











import Vue from "vue";
import Hello from "@/components/sections/Hello.vue";
import Institute from "@/components/sections/Institute.vue";
import Care from "@/components/sections/Care.vue";
import Products from "@/components/sections/Products.vue";
import Contact from "@/components/sections/Contact.vue";
import Footer from "@/components/sections/Footer.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Hello,
    Institute,
    Care,
    Products,
    Contact,
    Footer
  }
});
