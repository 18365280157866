import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=254f4a14&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=254f4a14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254f4a14",
  null
  
)

export default component.exports