<template>
  <!-- Care -->
  <section id="care">
    <div class="content-text">
      <h2 v-scrollanimation title="Nos soins" class="title-classic"><span class="bordered-title">Nos</span> Soins</h2>
      <div v-scrollanimation class="mobile content-img">
        <img src="@/assets/images/salon.jpg" alt="institut" />
      </div>
      <p v-scrollanimation>Nous proposons des soins visages et corps. L’épilation et l'onglerie font également partie de la gamme de soins que nous proposons.</p>
      <div class="align-content-right">
        <a v-scrollanimation href="#care" class="btn-classic" id="show-modal" @click="showModal = true" title="Voir la liste des soins">Liste des soins</a>
          <Modal v-if="showModal" @close="showModal = false" img="liste-soins.jpeg" />
        <a v-scrollanimation target="_new" href="https://app.kiute.com/l_institut-delphine" class="btn-classic revert" title="Prendre un rendez-vous">Prendre RDV</a> 
      </div>
    </div>
    <div v-scrollanimation class="content-img">
      <img src="@/assets/images/salon.jpg" alt="institut" />
    </div>
    <StepIndicatorUi v-scrollanimation link="#products" textFirst="Découvrir" textSecond="nos produits" />
  </section>
</template>

<script>
  import StepIndicatorUi from "@/components/ui/StepIndicatorUi.vue";
  import Modal from "@/components/ui/Modal.vue";
  export default {
    name: "Care",
    components: {
      StepIndicatorUi,
      Modal
    },
     data(){
        return{
            showModal: false
        }
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/variables';

  #care {
    text-align: center;
    height:auto;
     .mobile{
        display:none !important;
      }
    .content-text {
      width: 50%;
      text-align: left;
      display: inline-block;
      vertical-align: top;
      padding-right: 30px;

      p {
        font-size: $home-paragraph;
        font-weight: 300;

        &.before-enter {
          opacity: 0;
          transform: translateY(100px);
          transition: all 1s ease-out;
          transition-delay: 0.5s;
        }

        &.enter {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }

    .content-img {
      width: 40%;
      height: 70vh;
      text-align: left;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      margin-top:50px;
      border-radius: 50px;

      &.before-enter {
        opacity: 0;
        transform: translateX(100px);
        transition: all 1s ease-out;
        transition-delay: 0.5s;
      }

      &.enter {
        transform: translateX(0px);
        opacity: 1;
      }
    }

    img {
      width: 168%;
      margin-left: -225px;
    }

    .next-step-indicator,
    .btn-classic {
      &.before-enter {
        opacity: 0;
        transition: opacity 2s ease-out;
        transition-delay: 1s;
      }

      &.enter {
        opacity: 1;
      }
    }

  }
  @media screen and (max-width:1217px) {
      #care {
      .align-content-right{
        padding-right:0; 
      }
      .content-text {
        p {
          font-size: $home-paragraph - 5px;
        }
      }
    }
  }

  @media screen and (max-width:791px) {

    #care {
      .next-step-indicator{
          position:static;
      }
      .content-text {
        width: 100%;
        display: block;
        padding-right: 0px;
      }
      .align-content-right{
        text-align:center;
          .btn-classic{
            display:block;
            margin-bottom: 30px;
          }
           a{
            margin-left:0;
           }
      }

      .content-img {
        width: 100%;
        height: auto;
        text-align: left;
        overflow: hidden;
        border-radius: 50px;
        display:none;
        &.mobile{
          display:block !important;
          img{
            margin-left:0;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }
</style>