




















import Vue from "vue";
import StepIndicatorUi from "@/components/ui/StepIndicatorUi.vue";
import Flower from "@/components/animations/Flower.vue";

export default Vue.extend({
  name: "Hello",
  components: {
    StepIndicatorUi,
    Flower
  }
});
