<template>
<transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <button type="button" class="btn-close" @click="$emit('close')">
          <svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11'>
            <path fill='#23fff' d='M9.83 0L11 1.205 6.712 5.512l4.263 4.283L9.807 11 5.494 6.73 1.254 11 .024 9.795 4.29 5.512 0 1.23 1.218 0l4.3 4.32L9.83 0z'/> 
          </svg>
          </button>
          <div class="modal-header">
            <h3>
              Liste de nos soins
            </h3>
          </div>

          <div class="modal-body">
              <img src="@/assets/images/liste-soin-part-1.jpeg" alt="liste des soins" />
              <img src="@/assets/images/liste-soin-part-2.jpeg" alt="liste des soins" />
              <img src="@/assets/images/liste-soin-part-3.jpeg" alt="liste des soins" />
          </div>


        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
    name: "Modal",
    props: {
      img: {
        type: String,
        default: ""
      }
    }
  };
</script>
<style lang="scss" scoped>
img{ 
    width:100%;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  height: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: scroll;
  position:relative;
}

.modal-header h3 {
  margin-top: 0;
  text-align: center;
  padding-top:30px;
  text-transform:uppercase;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-close{
    cursor: pointer;
    border: none;
    width: 34px;
    height: 35px;
    margin: 15px 15px;
    border-radius: 50%;
    padding: 10px 10px;
    position: fixed;
    top: 5px;
    right: 10%;
    z-index: 1000;
    &:hover{
        background-color: #c5c5c5;
    }
}
.btn-close:hover{
}
</style>