<template>
  <div id="app" :class="{contrasted: contrast, noAnimated: noAnimation, dysFont: font}">
    <AccessibilityBar @accessibility-param="accessibilityParam"/>
          <header>
            <nav v-scrollanimation>
                <div id="logo">
                <a href="./" title="Logo delphine institut de beauté">
                    <img alt="Institut-Delphine logo" src="@/assets/images/logo.png" />
                </a>
                </div>
                <svg id="bgr-menu" class="hidden-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 28">
                      <g id="cross" data-name="cross" fill="black">
                        <path class="cls-1" d="M41,14H7a2,2,0,0,1,0-4H41a2,2,0,0,1,0,4Z" transform="translate(-5 -10)"/>
                        <path class="cls-1" d="M41,26H7a2,2,0,0,1,0-4H41a2,2,0,0,1,0,4Z" transform="translate(-5 -10)"/>
                        <path class="cls-1" d="M41,38H7a2,2,0,0,1,0-4H41a2,2,0,0,1,0,4Z" transform="translate(-5 -10)"/>
                      </g>
                       <g id="cross-2" data-name="cross-2" fill="black">
                        <path class="cls-1" d="M29.33,3.57l-26,21.86A2,2,0,0,1,.71,22.36L26.76.51a2,2,0,1,1,2.57,3.06Z"/>
                        <path class="cls-1" d="M26.72,25.46.74,3.53A2,2,0,0,1,3.32.47l26,21.94a2,2,0,1,1-2.58,3.05Z"/>
                      </g>
                </svg>
                <ul>
                  <li>
                      <router-link to="/">Accueil</router-link>
                  </li>
                  <li>
                      <router-link to="/#institute">L'institut</router-link>
                  </li>
                  <li>
                      <router-link to="/#care">Nos Soins</router-link>
                  </li>
                  <li>
                      <router-link to="/#products">Nos Produits</router-link>
                  </li>
                  <li>
                      <router-link to="/#contact">Contact</router-link>
                  </li>
                   <li>
                      <a id="booking" class="btn-classic revert" target="_new" title="Réserver un soin" href="https://app.kiute.com/l_institut-delphine">Réserver</a>
                  </li>
                </ul>
                <!-- <div class="ec-cart-widget"></div> -->
            </nav>
        </header>
    <router-view />
  </div>
</template>
<script>
document.addEventListener("DOMContentLoaded", (event) => {
  document.getElementById("bgr-menu").addEventListener('click', function(){
      if(this.classList.contains('show-menu')){
        this.classList.remove('show-menu')
        this.classList.add('hidden-menu');
      }else{
        this.classList.remove('hidden-menu')
        this.classList.add('show-menu');
      }
  });
});
  import Vue from "vue";
  import AccessibilityBar from "@/components/ui/AccessibilityBar.vue";
  
  export default Vue.extend({
    name: "App",
    components: {
      AccessibilityBar
    },
    data(){
      return{
        contrast: false,
        noAnimation: false,
        font: false,
      }
    },
    mounted(){
      const param = this.$cookies.get("Accessibility");
        if(param != null){
          this.noAnimation = (param.split('&')[0].split(':')[1] === 'true');

          //stopping animation of flower
          if(this.noAnimation){
            const st0 = document.getElementsByClassName('st0');
            for (let i = 0; i < st0.length; i++) {
                st0[i].classList.add('filled');
            }
          }

          this.contrast = (param.split('&')[1].split(':')[1] === 'true');
          this.font = (param.split('&')[2].split(':')[1] === 'true');
        }
    },
    methods: {
      accessibilityParam(e){
        if(e != null){
          this.noAnimation = (e.split('&')[0].split(':')[1] === 'true');
          this.contrast = (e.split('&')[1].split(':')[1] === 'true');
          this.font = (e.split('&')[2].split(':')[1] === 'true');
        }
    }
  }
  });
</script>
<style lang="scss">
  @import 'assets/scss/variables', 'assets/scss/font';
  #ecwid_body #app{
    overflow: auto;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    overflow: hidden;
    color: #2c3e50;
    #bgr-menu{
      display:none;
    }
    &.contrasted{
      * {
        font-weight: bold !important;
      }
      h1{
        font-size: 69px !important;
      }
      h2{
       font-size: 40px !important;
      }
      #contact {
        .aligned-block .phone{
          font-size: 41px !important;
        }
        .opens{
        font-size: 26px !important;
        }
      }
    }

    &.noAnimated{
      *{
        transform:none !important;
        transition:none !important;
        animation:none !important;
        animation-delay: 0s;
      }
   /*   .show-menu{
        #cross path:first-child{
          transform: rotate(-40deg) translate(-22px, 12px);
        }
        #cross path:nth-child(2){
          transform: rotate(40deg) translate(0px, -21px);
        }
      }*/
    }

    &.dysFont{
      *{
        font-family: 'OpenDyslexic' !important;
      }
      h1{
        font-size: 59px !important;
      }
      h2{
       font-size: 40px !important;
      }
      p{
        font-size: 30px !important;
      }
      .product-item p {
        font-size: 14px !important;
      }
      #contact {
        .aligned-block .phone{
          font-size: 41px !important;
        }
        .opens{
        font-size: 26px !important;
        }
      }
      #accessibility-bar p{
        font-size: 16px !important;
      }
    }
  }


  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Roboto';
  }

  /** Btn style **/
  .btn-classic {
    background-color: transparent;
    color: $dark-black;
    border: 4px solid $dark-black;
    border-radius: 15px;
    padding: 10px 40px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;

    &:hover {
      background-color: $dark-black;
      color: $white;
    }
  }

  .btn-classic.revert {
    background-color: $dark-black;
    color: $white;

    &:hover {
      background-color: transparent;
      color: $dark-black;
    }
  }

  /** END BTN STYLE**/

  /** Header style **/
  header {
    position: fixed;
    top: 50px;
    height: 80px;
    width: 100%;
    z-index: 99;
    background-color: $white;
    font-family: 'Typewriter-Serial';
    
    #bgr-menu{
      display:none;
    }
    /** nav style **/
    nav {
      padding: 15px 50px;

      &.before-enter {
        opacity: 0;
        width: 0;
        transition: all 3s ease-out;
      }

      &.enter {
        opacity: 1;
        width: auto;
      }

      div {
        float: left;
      }

      ul {
        float: right;

        li {
          display: inline-block;
          vertical-align: middle;
          font-size: 15px;
          list-style: none;

          &+li:before {
            content: "";
            display: inline-block;
            background-color: $dark-black;
            vertical-align: middle;
            width: 5px;
            height: 1px;
            margin-left: 5px;
            margin-right: 5px;
          }

          a {
            text-decoration: none;
            color: $dark-black;
          }
        }
      }
    }
  }
header nav ul li:last-child{
  padding-left:30px;
}
  header nav ul li:last-child:before{
    display:none;
  }

  #booking{
    padding: 5px 15px;
    font-size: 14px;
    font-family: 'Avenir';
  }

  /** Text style **/
  .title-classic {
    font-family: "Roboto";
    font-weight: 900;
    font-size: 50px;
    text-transform: uppercase;
    color: $dark-black;

    &.before-enter {
      opacity: 0;
      transform: translateY(100px);
      transition: transform 1s ease-out, opacity 1s ease-out;
    }

    &.enter {
      opacity: 1;
      transform: translateY(0px);
    }

    .bordered-title {
      color: $white;
      -webkit-text-fill-color: $white;
      /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $dark-black;
    }
  }

  /** SECTIONS STYLE **/
  section {
    position: relative;
    height: 90vh;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 100px;

    .align-content-right {
      text-align: right;
      padding-right: 50px;

      a {
        margin-left: 15px;
      }
    }
  }

  /** END SECTIONS **/

  @media screen and (max-width:769px){
  #app {
        #bgr-menu{
          display: block;
          float: right;
          width: 40px;
          height: 65px;
          cursor: pointer;
        }
         .show-menu{
          #cross{
            display:none;
          }
          #cross-2{
            display:block;
          }
         /* #cross path:first-child{
            transform: rotate(-40deg) translate(-22px, 12px);
          }
          #cross path:nth-child(2){
            transform: rotate(40deg) translate(0px, -21px);
          }
          path:last-child{
            opacity:0;
          }*/
          &+ul {
            display:block;
          }
        } 
        .hidden-menu{
          #cross{
            display:block;
          }
          #cross-2{
            display:none;
          }
          /*path:last-child{
            opacity:1;
          }
          #cross path{
            transform: none;
          }*/
          &+ul {
            display:none;
          }
        } 
        header{
        top: 75px;
        /** nav style **/
        nav {
          padding: 15px 50px;

          div {
            float: left;
          }

          ul {
            float: none;
            text-align:center;
            position: fixed;
            background: white;
            width: 100%;
            padding: 0;
            left: 0;
            top: 139px;
            li {
              display: block;
              vertical-align: middle;
              padding-bottom: 30px;
              font-size: 20px;
              list-style: none;
              padding-left:0;
              &+li:before {
                display: none;
              }

              a {
                text-decoration: none;
                color: $dark-black;
              }
              #booking{
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  /** END HEADER **/
  @media screen and (max-width:998px) {
    section{
      padding-left: 30px;
      padding-right: 30px;
    }
    #app {
       &.contrasted,&.dysFont{
        h1{
          font-size: 51px !important;
        }
      }
    }
  }
  @media screen and (max-width:791px) {
    #logo{
      height: 45px;
      padding-top:10px;
      img{
        height:100%;
        width:auto;
      }
    }
    #app {
      &.contrasted{
      header{
          top: 100px;
          nav ul {
            top: 164px;
          }
        }
        #institute, #care{
          height:auto;
        }
      } 
      &.dysFont{
        header{
          top: 100px;
          nav ul {
            top: 164px;
          }
        }
        #institute, #care{
          height:auto;
        }
        h1{
          font-size: 40px !important;
        }
        h2{
        font-size: 40px !important;
        }
        p{
          font-size: 20px !important;
        }
        .product-item p {
          font-size: 14px !important;
        }
        #contact {
          .aligned-block .phone{
            font-size: 41px !important;
          }
          .opens{
          font-size: 26px !important;
          }
        }
        #accessibility-bar p{
          font-size: 16px !important;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    section{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
</style>