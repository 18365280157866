<template>
	<div class="flower" :style="{ height: height, width: width }">
		<svg :class="color" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 318.4 629">
        <clipPath id="clipFlower1">
            <path d="M122.9,617.5l-5.6-0.7c0,0,8.2-101.4,13.1-109.6c-10.3-44.8-11.3-91.3-3-136.6l13.5-56.5l3.4-10.1l12.7-52.8
			c0,0,12.6-62.7,12-117.5c0,0-0.8-24.8-5.3-34.8c0,0-17.1-33.4-10.1-48.3c0,0-6.7-4,1.1-15.3c0,0,3.2-19.8-13.8-14.6
			c0,0,12.3-18.7,29.9,3.7c0,0,21.7-0.5,16.1,23.2c0,0,15.7,11.4,8.4,39.6c-3.1,10.1-5.3,20.5-6.5,30.9c0,0,0.9,19-7.1,32.9
			c-4.2,4.2-6.2,10.2-5.2,16.1c0.6,4.2,1,8.5,1.2,12.8c0,0,0.6,6.4-4.5,7l-3.9,24.1l-6.4,40.4c0,0-11.7,50.1-13.1,53.4l-3,10.9
			l-13.1,52.8l-2.6,19.5c0,0-5.5,63.1,2.6,100.3c3.3-11.7,5.4-23.7,6.3-35.8l6.1-25.2c0,0,9.2-51.6,19.8-88.7c0,0,0.2-26-27.3-24.3
			c-8.8,0.3-17.1,3.6-23.7,9.4c-1.5,1.6-2.6,3.5-3.2,5.6c0,0-8.8-10.4,4.1-25.1c0,0,2.7-3,4.5-3c0,0,20.3-1.4,35.2,6.4l12-5.2
			c0,0-7.9-11.7,1.9-27.7c0,0,12-21.3,32.6-4.5c0,0,7.5,4.6,13.1,1.9c1-0.4,2.2,0.2,2.5,1.2c0,0.1,0.1,0.2,0.1,0.3
			c2.7,5.7,8.7,9,15,8.2c0,0,21.3-4.6,22.1,19.5c9.6,4.3,14.5,15,11.6,25.1c-6.1-8.6-17.4-11.6-26.9-7.1c0,0-11.7,3.7-18.3-3
			c0,0-5.1-2.3-18.3,3.7c-2.4,1.2-4.9,2.1-7.5,2.6c0,0-19.6,2.7-24.7,19.8c0,0-18.7,77.6-19.5,93.5l-5.6,25.4l-1,4.5l-6.5,41.4
			l-6,41.5C131.8,548.5,122.6,615.4,122.9,617.5z" />
        </clipPath>
        <clipPath id="clipFlower2">
            <path d="M163,627.3c3.2-9.1,4.1-18.9,2.6-28.4l-4.1-45.3c0,0-4.9-46.2-7.1-49.7l-5.3-10.6c0,0-2.9-30.2-3.7-32.1
			l-5.6-9.3c0,0,2.1-34.8-8.6-64.1c-0.8-5.8-2.1-11.6-3.7-17.2c0,0-10.4-27.9-9.3-47.5L116,304c0.2-8.5-3.9-16.4-11-21.2
			c0,0-7.5-5.4-24.3-3.2c0,0-21,1.7-26.7,8.3c0,0-10,15.5-26.2,14.4c-8.5,0.8-16.6,4.1-23.3,9.4c-1.9,1.7-3.4,3.8-4.5,6.1l3.3-11.5
			l5.1-15.6L13,279c1.7-4.3,3.8-8.5,6.1-12.5c2.1-11.6,8.1-22.1,16.9-29.9c0,0,12-11.6,33.7-6.5c0,0-0.8-15,14.2-10.1
			c0,0,1.5-14.2,12.7-9c0,0,18.3-11.9,22.5-1.8c4.7,9.5,6.3,20.2,4.8,30.6c8.3-17.7,26.1-29.1,45.7-29.2c13.3,0.6,25.8,6.7,34.4,16.8
			c-9.7,2.4-18.6,7.6-25.4,15c-3,5.6-9,9-15.3,8.6c0,0-4.7,0-8.8-0.2c-8.3,0.1-16.1,4.1-21.1,10.8c0,0-5.4,4.2-9.8,22.5
			c0,0-3.6,14-2.9,16.7l4.5,18c0,13.4,1.8,26.7,5.2,39.7l3.7,9.7c0,0,12.3,49.5,12.3,58.7l4.9,8.6l4.9,43.4l2.2,16.5
			c0,0,10,72.3,9,80.8l7.9-26.6c0,0-17.4-30.2-5.4-35.7c0,0,13.2-6.2,11,12.5l-1.9,15.7l5.6-11.6c0,0-8.8-6.8-11.7-24.7
			c0,0-3.2-14,10.6-9c2.9,1.1,4.7,4,4.5,7.1c0,0-2.5,16.1,0.7,19.8c3.9-5.6,3.9-13.1,0-18.7c-2.2-2.9-4-6-5.4-9.3c0,0-5.4-22,9.4-15
			c2.8,2.1,4.5,5.3,4.7,8.8c0.8,5.8,0.5,11.6-0.7,17.3l-0.7,3.7c0,0,12-39.2,25.1-29.6c0,0,11,13.4-21.7,30.7c0,0,35.2-11.5,35.9-0.4
			c0,0,0.9,16.4-37.4,3.7l-6.7,13.1c0,0,30.8-13.5,32.9-1.5c0,0,3.5,15.2-34.4,5.2l-7.1,14.6c0,0,31.1-15.7,35.2-2.6
			c0,0,2.4,17-37.4,6l-4.9,19.8c0,0,28.2-17.6,34.8-4.5c0,0,6.6,17.3-35.5,7.9l-4.5,15.7c0,0,11.2-3.6-3,40L163,627.3z" />
        </clipPath>
        <clipPath id="clipFlower3">
            <path d="M128.9,620.5l4.5,1.1l-1.1-10.9c0,0,6.6-64.2,22.1-107l3.7-8.2l0.7-6l1.3-3.7l16.7-35.2l18.3-36.5c0,0,15.8-34.9,30.7-95.2
			c0,0-0.9-4.3-4.9-3c0,0-12.1,54.4-29.9,94.2c-4.3,10-9.1,19.8-14.4,29.3l17.7-80.8l8.3-38.9l15-46.4c0.6-4.3,1.7-8.5,3.2-12.5
			c3.3-8.7,8-16.8,14-23.9c6.7-8,14.3-15.2,22.8-21.2c0,0,18.7-12,31.2-50.6c0,0,7.5-35.8,29.4-27.2c0,0-18-17.5-38.1-9.7
			c0,0-12.6,4-22.5,21.3c-10.2-3.1-21.1,2.7-24.2,12.9c-0.2,0.7-0.4,1.4-0.5,2.1c0,0-3.6,13.9-8.6,0.7c0,0-11.6-13.6-26.2-3
			c0,0,0.3,13.9,18.7,10.5l2.4-0.4c0,0,7.5-1.5,3.7,4.6c0,0-19.4,2.2-27.1,12.5c0,0-21.9,17.7,3.7,30.6c0,0,31.2,3.2,22.2,35.7
			l-1.4,4.6c-1.5,4.1-3.3,8-5.5,11.8L195,322.6c0,0-3.7,24.2-6.1,34.4c0,0-18.5,81.2-33.2,122.4L149,494l-11.5,50.4l-3.3,19.4
			l-3.7,35.7L128.9,620.5z" />
        </clipPath>
        <g clip-path="url(#clipFlower1)">
            <g class="a-fill filler">
                <path fill="black" id="ink-1-shape" d="M223.8-492.9c4.9-5.7,6.3-12.9,8-19.9c0.4-1.8,2.1-4.2-0.2-6c0.5-0.5,0.8-1.2,1.4-1.5c2.6-1.4,3.2-2.1,1.7-3.3
				c-1.5-1.2-1-2-0.6-3c4.3-12.6,7.4-25.7,13.7-38.4c0.1,1.4,0.2,2.1,0.4,3.9c2.9-6.8,5.1-12.9,7.2-19.1c-0.5-0.2-1-0.4-1.4-0.6
				c-1.1,2.7-2.1,5.4-3.4,8.5c-0.2-0.9-0.4-1.4-0.3-1.7c3.7-10.9,7.2-21.8,11.1-32.6c2.4-6.7,5.6-13.2,8.1-19.9
				c8.9-23.8,17.5-47.6,26.5-71.4c5.7-14.9,11.8-29.7,17.6-44.5c3-7.8,5.7-15.7,8.8-23.4c3.6-9.1,7.7-18.1,11.2-27.3
				c0.9-2.4,4.2-0.6,4.2-4.2c0.1-3.6,1.3-7.4,2.6-10.9c2.7-7.2,5.7-14.2,8.8-21.2c0.3-0.7,1.9-0.8,3.6-1.4c3.3-8.3,7.8-17.3,10.2-26.9
				c1.3-5.3,5-8.8,6.4-13.8c0.8-2.8,0-6.5,3-8.6c0.2-0.1,0.3-1,0.1-1.2c-3.7-3.4-0.2-6.6,0.6-9.8c0.6-2.3,1.2-4.5,1.9-6.8
				c0.6-2.1,1.4-4.2,2.1-6.3c0.4,0.1,0.8,0.2,1.2,0.4c-0.6,4-1.4,8-1.9,12.1c-0.4,3.3,0.1,6.7-0.6,9.9c-0.8,4.1-2.1,8.2-3.7,12.1
				c-2.4,6.2-5.3,12.1-7.9,18.2c-3.2,7.7-6.2,15.4-9.4,23.1c-3.8,9.3-7.7,18.5-11.5,27.7c-5.9,14.2-11.8,28.4-17.5,42.6
				c-3.6,8.8-6.9,17.8-10.5,26.6c-3.6,8.9-7.2,17.9-10.8,26.8c-0.6,1.4-1.1,2.7-1.6,4.1c-5.7,15.5-11.3,31-17.1,46.5
				c-7.3,19.7-14.8,39.4-22,59.1c-4.1,11.1-7.9,22.4-11.6,33.6c-3.1,9.5-5.7,19.2-9,28.6c-1.6,4.5-1.1,10.3-6.4,13.3
				c-0.8,0.4-0.6,3.5-0.4,5.2c0.2,1.5-0.1,1.5-1.3,2.3c-1.4,1-2.7,4.5-2.1,6c1.5,3.8-0.7,6.5-1.9,10.6c-0.2-1.7-0.2-2.4-0.3-3.1
				c-0.3,0.1-0.8,0.2-0.8,0.4c-0.7,2-1.5,4-2,6.1c-0.4,1.9-2.3,3.8,0,5.8c0.4,0.4-0.3,2.3-0.7,3.5c-2.3,7.5-4.6,15-6.9,22.5
				c-0.9,3-1,6.2-3.8,8.3c-0.6,0.4-0.8,1.5-1.1,2.3c-1.5,5.8-2.8,11.7-4.4,17.5c-0.7,2.4-1.8,4.7-2.6,7.1c-0.4,1-1.2,2.1-1,2.9
				c1,4.3-1.3,7.3-3.5,10.3c1.3,3.1,3.2,2.5,5.5,0.3c-1.2,3.7-2.2,6.9-3.2,10.1c-0.1,0-0.2,0-0.3,0c0.3-1.9,0.5-3.7,1-7.2
				c-2,1.8-3.4,2.4-3.7,3.5c-1.9,5.2-4,10.4-5.1,15.8c-4.4,20.7-11.6,40.6-17.2,61c-2.3,8.4-4.2,16.8-6.4,25.2
				c-1.2,4.3-2.9,8.5-4.2,12.8c-0.6,1.9-0.8,3.9-1.1,5.9c0.4,0.1,0.8,0.2,1.2,0.3c0.7-1.8,1.5-3.6,2.1-5.5
				c8.2-25.4,16.3-50.9,24.8-76.3c2.4-7.2,5.9-14.1,8.7-21.2c1.6-4,2.7-8.1,4-12.2c2.5-7.9,5-15.7,7.8-24.6c2.9,4.4,0.5,7.7,0,10.9
				c2.9-3.8,4.9-9.5,5.1-15.2c-0.1-0.1-0.3-0.4-0.4-0.3c-0.6,0.4-1.2,0.8-2.2,1.5c-0.1-1.1-0.5-1.9-0.2-2.6
				c4.6-14.5,9.2-29.1,14.1-44.4c1.5,1.4,2.4,2.2,3.5,3.1c4.6-7.1,6-15.4,8.7-23.2c1.7-5,3.3-10.1,4.9-15.2c1.8-5.6,3.6-11.3,5.5-16.9
				c2.2-6.5,4.6-12.9,6.8-19.3c0.8-2.2,1.2-4.5,2-6.7c3.6-9,6.8-18.2,9.5-27.5c0.5-1.6,1.9-2.9,2.7-4.4c0.4-0.7,0.4-1.6,0.5-2.4
				c0-0.6-0.2-1.7-0.1-1.8c5.1-1.9,2.5-6.8,4-10.1c2-4.6,2.6-9.6,6.2-13.8c2-2.3,1.9-6.5,3-9.8c5.4-16,10.8-31.9,16.4-47.8
				c2.3-6.6,4.1-13.6,7.8-19.4c4.6-7.4,6.2-15.8,10.2-23.4c2.4-4.6,4.8-9.7,5.5-15.3c0.6-4.6,2.5-9.1,4.2-13.4
				c4.1-10.5,8.3-21,12.6-31.5c0.7-1.7,1.8-3.3,3.5-4.7c-1.1,3.5-2.2,7.1-3.4,10.6c0.3,0.1,0.6,0.2,1,0.3c0.5-1.3,1-2.6,1.6-3.9
				c2-4.2,4.1-8.3,6.1-12.5c1.4-3.1,2.8-6.1,4-9.3c1.1-3,1.5-6.2,2.8-9.1c4.8-11.1,9.9-22.1,14.8-33.2c0.7-1.5,0.5-3.4,1.3-4.8
				c0.4-0.8,2-1.7,2.7-1.5s1.5,1.8,1.4,2.8c-0.1,1.4-0.9,2.8-1.5,4.2c-2.8,6.4-5.8,12.7-8.5,19.1c-3.4,8.2-6.3,16.6-9.8,24.8
				c-3.1,7.4-6.6,14.5-9.8,21.8c-0.9,2.1-1.2,4.4-2.1,6.5c-3.6,8.6-7.4,17.1-10.8,25.8c-2.2,5.7-3.9,11.7-5.9,17.6
				c-1,3-1.7,6.3-3.1,9.1c-4,8-6.8,16.4-9.8,24.7c-5.3,14.7-11.3,29.2-16.8,43.8c-4,10.7-7.6,21.7-11.5,32.4
				c-3.4,9.2-7.1,18.3-10.7,27.5c-0.7,1.8-1.5,3.6-2.1,5.4c-5.7,17.6-10.7,35.5-17.2,52.8c-4,10.6-6.5,21.6-10.3,32.2
				c-2.4,6.7-5.1,13.3-7.7,20c-0.3,0.8-0.5,1.6-0.6,2.4c-2.6,15.4-8.2,29.9-12.4,44.9c-0.4,1.3-0.6,2.7-0.9,4c0.4,0.2,0.8,0.3,1.2,0.5
				c0.8-1.8,1.6-3.5,2.2-5.4c7.4-23.2,14.6-46.5,22.3-69.7c7.6-22.9,15.8-45.7,23.8-68.5c3.4-9.7,6.6-19.4,10.1-29
				c9.5-26.3,18.8-52.7,28.7-78.9c10.2-27.2,20.8-54.2,31.4-81.2c5.7-14.5,11.9-28.7,17.6-43.2c5.4-13.6,10.4-27.4,15.8-41.1
				c1.9-4.7,4.4-9.1,6.5-13.8c3.7-8.4,7.3-16.8,11-25.2c7.6-17.6,14.8-35.2,20.8-53.4c0.1-0.3,0.5-0.5,1.6-1.6
				c-4,12.6-7.7,24.1-11.3,35.6c1.3,0.8,2.9,1.3,2.9,1.8c-0.1,1.7-0.6,3.3-1.2,4.9c-4.5,13.4-9.1,26.7-13.7,40.1
				c-0.5,1.5-1,3.1-0.9,4.8c5.3-8.5,7.4-18.2,11.9-27.3c-0.2,1.7,0,3.6-0.6,5.2c-7.1,21-13.4,42.3-22.4,62.7
				c-5,11.3-9.5,22.9-14.2,34.4c-0.4,0.9-0.6,1.9-0.6,3.1c8.6-13.4,12.7-29.2,21.5-42.6c-1.5,6.5-6.9,11.8-5.5,20.2
				c1.2-3.1,1.9-5.3,2.9-7.4c0.9-2.1,2.2-4.1,3.1-6.3c3.8-9.2,7.6-18.5,11.3-27.7c2.3-5.7,5-11.3,7-17.1c4.8-13.7,9.3-27.5,14.1-41.2
				c1.2-3.6,3.2-7,4.3-10.6c1.5-4.9,2.3-9.9,3.6-14.9c0.5-2,1.3-4,3-6c-0.3,3.4-0.1,7-1,10.3c-3.2,12.1-6.4,24.3-10.1,36.2
				c-3.4,10.7-7.2,21.4-11.5,31.8c-2.1,5.1-5.1,9.8-5.1,15.2c2.5-4.5,5.2-9.3,7.6-13.6c2.1,0.2,3.7,0.4,5.7,0.6
				c-0.4,1.6-0.8,3.5-1.2,5.5c0.2,0.1,0.4,0.2,0.6,0.3c0.8-2.1,1.5-4.3,2.5-6.3c1.1-2.3,2.6-4.4,3.7-6.7c1.6-3.2,1.6-7.4,5.8-8.9
				c0.6-0.2,0.9-1.8,1.3-2.8c3.4-10.1,6.6-20.2,10.1-30.2c3.1-9.1,6.3-18.1,9.4-27.1c0.2-0.6,0.7-1.3,0.6-1.8
				c-1.6-8.2,3.3-15.1,4.9-22.6c1-4.6,2.8-9,4.1-13.2c-1.3,7.2-2.4,14.7-4.1,22c-1.8,7.7-4.2,15.2-5.8,23c5.6-3.8,5.2-11.1,9.4-17.2
				c0.4,6.9-3.4,12-3,18.1c1.8-1.2,3.4-2.1,5.5-3.5c-0.4,3.4-0.8,6-1.1,8.5c0.3,0.2,0.6,0.4,0.9,0.6c1.1-1,2.4-1.8,3.1-3.1
				c1.2-2.2,1.9-4.7,2.9-7.1c2.5-6.1,5.2-12.1,7.7-18.2c2.2-5.5,4.1-11.1,6.4-17.3c2.3,3.3,1.4,6.2,0.5,9.2
				c-6.5,20.8-13,41.6-19.5,62.5c-0.5,1.6-0.9,3.2-1.7,6.3c5.1-4.6,4.7-10.5,6.9-14.1c0.3,16.4-5.8,32.1-10.8,48
				c-0.8,2.6-1.7,5.2-2.5,7.8c0.4,0.1,0.7,0.3,1.1,0.4c2.1-4.9,4.2-9.9,6.4-14.8c1.9-4.2,2.8-8.8,6-12.5c2-2.4,2.4-6.3,3.5-9.6
				c5.1-15.8,10.3-31.7,15.4-47.5c0.2-0.6,0.2-1.4,0.6-1.8c4.4-4.6,5.2-10.8,6.9-16.6c5.3-18.3,10.4-36.6,15.8-54.9
				c3.6-12.4,7.4-24.9,11.4-37.2c0.7-2.3,2.5-4.3,4.3-6.2c-4.6,27.7-13.8,54.4-18.8,82.1c3.5-3.6,8-6.3,8.2-12.3
				c7.6,2.4,6.2-5.1,7.9-7.6c0.1,2,0.2,4.8,0.2,7.7c0,1-0.1,2.1-0.4,3.2c-3,11.9-4.6,24.2-8.6,35.9c-0.5,1.5-0.6,3.1-0.8,4.7
				c0.5,0.2,0.9,0.3,1.4,0.5c1.5-3.4,3-6.9,4.6-10.3c-1.5,16-5.2,31.5-10.1,47.2c5.1-4,7.2-7.7,8.9-13c2.2-7.1,4.9-14.1,7.5-21.1
				c2.4-6.4,4.8-12.8,7.3-19.1c0.9-2.3,2.3-4.3,3.3-6.3c2.7,1.8,6.2,2.8,6.4,4.5c0.5,3.7,3.4,2.9,5.5,4.5c-0.1,1.3-0.2,2.8-0.4,5.2
				c1-1.3,1.8-1.9,1.9-2.6c0.3-3.4,1.1-5.9,4.3-7.9c1.6-1,2.4-3.8,3.1-6c2.5-7.5,4.8-15,7.7-22.6c0.4,6.5-3.2,13.3,0.8,19.7
				c2.6-0.5,1.5-3.9,2.7-5.7c1.3-1.9,2.6-3.8,3.8-5.6c1.7,3.6,0.4,7.3-0.6,11.2c-3.4,13.2-6.4,26.5-9.7,40.1c1.5,0.5,2.9,0.9,4.5,1.4
				c-0.1,1.4-0.4,2.8-0.4,4.2c-0.1,2.5,0,4.9,0,7.4c0,1.2,0,2.5-0.3,3.7c-2.5,10.8-5.1,21.6-7.4,32.5c-2,9-3.6,18-5.6,26.9
				c-1.3,5.8-3.1,11.5-1.6,18c1-1.2,2-2,2.3-3c5-16.3,9.8-32.8,14.9-49.1c4.6-14.8,9.5-29.6,14.5-44.3c1.3-3.9,3.8-7.5,5.6-11.3
				c1.4-2.8,2.5-5.7,4.1-9.4c0.1,6.5,0.2,12.1,0.2,17.6c5.5,0.5,6.2,0.1,7.4-4c0.7-2.7,1.1-5.5,1.9-8.1c1.2-4.1,3.3-7.4,7.6-9
				c1,1.4,1.8,2.6,2.9,4.1c4.6-3.6,6.9-8.3,8.3-13.4c1.8-6.3,3.1-12.7,4.7-19.1c0.9-3.9,0.9-3.9,5.6-6.2c-1.6,6.8-3,13.2-4.7,19.5
				c-0.7,2.5-0.7,4.4,0.9,6.4c2.4,3.1,2.3,6.4,0.6,10.4c-2.2,5.4-3.2,11.3-4,17.2c4.5-6,3.8-14.6,9.9-19.9c2.7,0.5,3.4,2.3,3.1,5.6
				c-0.7,10.5-4.2,20.4-6.6,30.5c-3.9,16.6-8.6,33.1-12.9,49.6c-0.1,0.4,0,0.8,0.1,1.7c1.2,0.1,2.5,0.2,3.5,0.3
				c3.3-7.8,6.7-15.3,9.6-23c2.8-7.5,5-15.3,7.4-22.9c3.5-11.2,6.9-22.5,10.5-33.7c0.5-1.7,1.5-3.3,2.3-5c0.4,0.1,0.9,0.2,1.3,0.4
				c-0.5,3-0.9,6-1.6,9c-3.4,13.7-7,27.3-10.4,41c-3,11.8-6.3,23.5-8.6,35.4c-1.6,8.4-3.5,16.6-5.3,24.9c-1,4.6-1.5,9.5-2.1,14.3
				c-2,14-6.5,27.4-10.5,40.9c-2.3,7.7-4.3,15.6-6.5,23.3c-2.1,7.6-3.9,15.3-6.3,22.8c-2.8,8.8-6.2,17.5-9.2,26.3
				c-2.6,7.5-5.2,15-7.8,22.5c-0.1,0.4-0.1,0.9-0.3,1.2c-5.9,9.7-8.1,20.8-12.3,31.2c-4.2,10.3-8.4,20.6-12.3,30.9
				c-3.7,9.9-7.2,19.9-10.7,29.9c-9.7,27.7-19.3,55.5-29,83.2c-7.2,20.6-15,41.1-21.9,61.9c-8.4,25.6-16.3,51.3-24.1,77.1
				c-4.7,15.5-8.6,31.3-13.2,46.8c-4.9,16.6-10.1,33.1-15.1,49.6c-4.7,15.5-9.3,31-14.2,46.4c-5.6,17.5-11.6,34.9-17.1,52.4
				c-5.1,16.3-9.8,32.7-14.8,49.1C401.6-85,396-66,389.9-47.1c-6.7,20.7-13.8,41.3-20.9,61.9c-4.1,11.9-8.8,23.6-12.9,35.5
				c-5.3,15.5-10.3,31.1-15.6,46.6c-6.8,20-13.9,40-20.6,60c-3.9,11.7-7.3,23.7-11.1,35.5c-6.1,19.1-12.3,38.2-18.4,57.3
				c-5,15.4-10.2,30.8-15.1,46.3c-4.5,14.3-8.5,28.7-13.1,42.9c-4.3,13.4-9.3,26.6-13.7,40c-2.6,7.9-4.8,16-7.1,24
				c-3.4,11.7-6.7,23.4-9.9,35.1c-2.7,9.7-5.4,19.4-7.9,29.1c-3.7,13.8-7.2,27.7-11,41.5c-5.8,20.8-11.4,41.6-17.7,62.3
				c-5.5,18-11.7,35.7-17.8,53.5c-4.5,13.2-9.8,26-17.2,37.8c-3,4.8-6.5,9.2-12.4,10.7c-1.9,0.5-3.5,2.6-5.6,4.3
				c-1.6-3.9-3.2-7.7-4.8-11.8c-3,2-5.9,3.8-9.4,6.1c-1.5-3.3-3.1-6.5-4.6-9.8c-3.3,1.3-6.7,2.6-10.4,4.1c-2.9-4.5-6.4-2-10,0.4
				c-1.5-2.7-2.8-5.2-3.9-7.1c-1.8-1.7-3.7,2.4-4.9-1.1c-0.2-0.6-3.1-0.1-5.1-0.1c-0.3-0.5-0.8-1.2-1.3-1.8c-4-5.7-4-11.2,0.4-17.3
				c2.1-3,3-7,4.3-10.6c0.2-0.6-0.3-1.5-0.7-3.4c-7.2,9.6-11.2,19.9-16.5,30c-1.6-3.9-4.9-6.5-3.3-11.6c0.5-1.7-0.6-4-1.2-6.9
				c-2.7,3-4.8,5.5-7.1,8c-2.3-2.1-4.2-5.1-7-0.2c-0.6,0.9-4.1,0.1-6.6,0c-0.6-2.2-1.2-4.6-2-7.7c-2.1,0.8-3.8,1.5-6.1,2.4
				c-1.1-1.4-2.3-4.1-5.2-2.4c-2,1.1-3.9,2.3-6.1,3.7c-1.5-0.5-3.1-1.1-4.8-1.5c-1.7-0.4-3.4-0.4-5.4-0.6c-1.7,1.5-3.6,3.1-5.5,4.8
				c-0.4-0.2-0.7-0.3-1.1-0.5c1.2-5.6,0.7-10.8-2.6-16.1c-2.6,4.4-4.8,8.3-7.2,12.4c-1.9-1.5-3.3-2.4-4.6-3.6c-3-3-4.9-6.3-3.4-11.2
				c1.3-4.2,2.1-8.7,3.3-13.6c-2.4,0.4-4.4,0.5-6.1,1.2c-1.5,0.6-3,1.8-4.2,3.1c-1.9,2.1-3.5,4.5-5.8,6.6c0-2.9,1.1-5.7-1.9-7.5
				c-0.6-0.3-0.3-2.7-0.1-4.1c0.4-2.4,1.1-4.8,1.6-7.1c-0.4-0.3-0.8-0.5-1.2-0.8c-1.9,1.4-3.8,2.8-6.1,4.5c-0.1-0.6-0.3-1.5-0.6-3.2
				c-4.2,5.9-7.9,11.2-12,17c-1.7-0.4-4.1-0.3-5.3-1.5c-2.5-2.5-5.4-3.2-8.7-3.3c-2.9-0.1-5.8-0.1-9.1-0.1c-5.5-5.7-5.6-6-2.2-14.3
				c1.9-4.5,3.9-9,5.8-13.5c0.4-1,0.4-2.2,0.5-3.3c-0.4-0.1-0.8-0.3-1.1-0.4c-0.7,1.3-1.5,2.6-2.1,3.9c-2.9,6.4-5.9,12.8-8.4,19.4
				c-1.6,4.1-4,5.6-8.4,5c0-1.7,0-3.5,0-5.4c-7.6-5.2-7.6-5.2-14.4,0.9c-0.5-2.1-1-3.8-1.2-5.6c-0.1-1.8,0.2-3.6-0.6-5.7
				c-1.5,2.2-2.9,4.4-4.7,7c-2.1-1.8-4-3.5-6.1-5.3c-3.7,2.3-7.1,2.4-10.7-0.6c-2.7-2.2-6.3-3.2-9.6-4.5c-1.2-0.5-2.6-0.4-3.9-0.2
				c-4.1,0.8-4.8,0.4-5.3-4.1c-0.2-2.3-0.5-4.2-2.2-6.1c-1.1-1.2-0.3-4.7,0.1-7.1c0.3-2.1,1.2-4,1.8-6.1c0.1-0.3-0.3-0.7-0.7-1.5
				c-2.4,0.6-3.1,2.8-4,4.9c-2.1,5-4.5,10-6.9,15.3c-4-1.9-7.3-3.5-11.2-5.3c1.5-5.8,3.4-11.2,4.3-16.8c3.4-21.6,12-41.8,17.3-63
				c7.8-31.8,16.4-63.4,24.8-95c2.9-11,6.4-21.8,9.5-32.7c1.2-4.1,1.8-8.4,3.1-12.4c1.1-3.5,1.1-7.3,5.4-9.4c2-0.9,3.3-4.9,4-7.7
				c2.4-8.9,5.1-17.8,6.6-26.8c5.1-31.3,14.3-61.6,22.6-92.1c3-10.9,6-21.9,9.2-32.8c0.9-3,2.1-5.9,4.3-8.7c0.1,1,0.3,2,0.2,3
				c-0.8,4.4-1.9,8.8-2.4,13.2c-0.3,2.6,0.6,5.3,1,8.6c-2.2,0.9-4.9,2.5-3.9,6.6c0.2,0.7-0.3,1.6-0.5,2.4c-2.7,8.7-5.4,17.5-8.1,26.2
				c-0.1,0.5,0.2,1.1,0.4,2.3c2.3-2.3,4.4-3.9,5.3-6.9c4.5-15.4,9-30.7,13.6-46.1c3.7-12.7,7.3-25.5,11.3-38.1
				C-19.1,128-10,99.9-1.1,71.8C9.7,38,20.6,4.2,31-29.7c3.3-10.8,6.8-21.4,11.2-31.8c0.9-2,0.5-4.5,1.2-6.7c2.7-9,5.4-18,8.6-26.9
				c3.7-10.2,8-20.1,12.5-30c-2,12-5.7,23.5-9.8,34.9c-3.1,8.6-6.6,17.2-9.3,26C43.5-58,42.5-51.4,41-45c0.4,0.1,0.8,0.3,1.1,0.4
				c0.6-0.9,1.3-1.7,1.7-2.7c5-14.1,10.1-28.1,14.9-42.3c1.2-3.6,1.5-7.6,2.6-11.2c2.4-7.7,5.3-15.3,7.8-23c1.3-3.8,2-7.8,3.3-11.6
				c4.2-12.5,8.5-25.1,13-37.5c1.4-4,3.5-7.8,5.5-12.2c0.8,0.1,1.8,0.2,3.2,0.3c2.6-8,5.1-16.1,7.7-24.2c-5.4,3.3-3.4,9.9-6.4,14.1
				c-0.2,0-0.5,0-0.6-0.1c-0.2-0.1-0.4-0.4-0.3-0.5c5.3-16.7,10.6-33.3,15.7-49.5c0.9-0.1,1.9-0.1,2.6-0.5c0.3-0.2,0.7-1.4,0.4-1.7
				c-3.3-6.3,1-11.7,2.4-17.5c3.5-15.5,9-30.3,13.7-45.4c0.4-1.3,1.1-2.6,2.7-3.6c-0.5,2.5-1,4.9-1.5,7.4c0.3,0.2,0.6,0.3,1,0.5
				c1.3-2.3,2.9-4.5,3.7-7c3.5-10.8,6.3-21.8,10.3-32.3c1.8-4.6,5-8.8,7.6-13.3c-2.8,8.8-5.7,17.6-8.4,26.4c-8,26-16,51.9-24,77.9
				c-7.8,25.3-15.7,50.6-23.4,75.9c-3.8,12.4-7.3,25-11.2,37.4c-4.2,13-8.8,25.9-13.1,38.9C62.5-70.3,51.8-38.7,41.6-7
				c-5.5,17.1-10.4,34.4-15.4,51.6c-3.5,12.3-6.6,24.7-10.1,37c-1.9,6.6-4.3,13-6.4,19.5c-0.4,1.2-0.2,2.5-0.3,3.8
				c0.5,0.1,1,0.2,1.6,0.3c3.2-9.3,6.4-18.6,9.6-28c0.4,0.1,0.7,0.2,1.1,0.2c-0.2,1-0.1,2.3-0.6,3.1c-1.5,2.2-1.5,4.6-2.1,7.1
				c-1.1,5.3-2.9,10.5-5.1,15.5c-2,4.5-3.5,8.9-4.4,13.7c-0.6,3.3-2.1,6.5-3,9.8c-0.9,3.4-1.5,6.9-2.3,10.8c3.4-0.9,3.4-3.4,4-5.3
				c1.6-4.7,3-9.4,4.5-14c0.8-2.3,0.2-5.8,4.2-5.4c0.4,0,1.1-1.7,1.4-2.7c4.3-13.4,8.6-26.9,12.8-40.3c0.4-1.4,0.5-2.9,0.8-4.2
				c-1.6-0.5-2.9-0.9-4.4-1.4c-1.2,2.6-2.4,5.2-3.6,7.9c-0.3-0.1-0.6-0.2-0.9-0.3c1.5-6.5,2.7-13,4.5-19.4c1.9-6.3,4.3-12.5,6.7-18.6
				c2.4-6,2.2-13,6.6-19c2.1,3.1-2.3,7.8,3,8c0.7,3.9-5.9,6.1-1.7,10.6c0.4-0.4,1.4-0.9,1.7-1.7c1.8-5.5,3.7-10.9,5.2-16.5
				c1.8-6.7,1.6-9.2-0.6-16.3c-0.3-1-1.8-1.6-2.8-2.4c2.4-9.2,3.8-18.7,8.8-27.2c1.6-2.8,1.8-6.5,2.8-9.7c0.6-1.9,0.5-4.3,3.2-5.1
				c0.5-0.1,0.8-2.6,0.6-3.8c-0.5-2.7,0.3-4.3,2.9-5c0-2.1,0-4.1-0.1-6.2c0.3-0.1,0.6-0.3,0.8-0.4c0.5,0.8,1.4,1.6,1.3,2.3
				c-0.3,3.6-0.2,7.3-1.8,10.8c-0.9,2-0.9,4.4-1.2,6.5c3,1,4.4,0.2,5.2-2.4c2-6.1,4.2-12.1,6.2-18.2c2.7-8.1,5.6-16.1,8-24.3
				C83.4-90,83.5-94.1,84-98c-0.4-0.1-0.8-0.2-1.1-0.3c-3,8.6-6,17.3-9.1,25.9c-0.3-0.1-0.7-0.1-1-0.2c0.1-1,0.5-2.7,0.1-3
				c-4.2-3.2-1.1-6.4,0-9.8c2.5-7.7,4.6-15.5,7-23.2c2.4-7.6,4.9-15.1,7.4-22.5c0.7-1.9,1.7-3.7,3.1-6.5c2.1,4.4-0.4,7.3-0.9,10.4
				c-0.6,3.3-0.9,6.7-1.7,10c-0.8,3.2-1.9,6.2-3.1,9.8c1.3-0.4,1.9-0.4,2-0.6c2.1-6.5,5.8-12.1,8.9-18.1c3.8-7.5,7-15.7,8.9-23.9
				c2.9-12.5,7.7-24.3,11.3-36.6c7.1-24.4,14.9-48.6,21.8-73.1c7-24.7,15-49,23.1-73.3c8.9-26.8,17.4-53.7,26-80.5
				c2.5-7.7,4.9-15.5,7.6-23.9c-1.1,0.5-1.7,0.8-2.6,1.3c0.1-2.6-0.2-4.9,2.9-6c0.9-0.3,1.8-2,2.1-3.2c4.2-13.2,8.3-26.5,12.5-39.8
				c4.4-14,8.8-28.1,13.4-42.1c6.6-20.3,13.4-40.6,20.2-60.8c2.6-7.9,5.5-15.6,8.3-23.4l0,0c0.6,0.7,1.8,1.4,1.7,2
				c-0.2,2.1-0.6,4.3-1.3,6.2c-7.1,20.4-14.6,40.6-21.5,61.1c-4.7,13.7-8.7,27.7-13,41.5c-3.9,12.7-7.9,25.3-11.9,38
				c-10.5,34.1-20.9,68.2-31.6,102.2c-3.8,12-8.5,23.7-12.2,35.7c-5.8,18.5-11.1,37.3-16.8,55.8c-4.9,15.9-10.1,31.7-15.1,47.6
				c-5.9,18.7-11.7,37.5-17.7,56.2c-1.9,6-5.6,11.8-6.5,17.9c-0.8,5.8-3.2,10.9-4.6,16.4c-2.3,8.9-4.7,17.7-7.7,26.4
				c-4,11.7-8.8,23.1-12.8,34.7c-1.7,4.8-2.1,10-3.5,14.8c-2.3,7.7-4.9,15.4-7.5,23c-0.6,1.9-2.2,3.9,0.5,6.3c1.6-3.7,3.4-7,4.7-10.5
				c3.7-10.5,6.9-21.1,11.8-31.2c2.2-4.5,2.9-9.8,4.5-14.6c2.4-7.5,5-15,7.6-22.4c2.8-8.3,5.8-16.6,8.4-24.9
				c6.2-19.6,12.1-39.3,18.4-58.8c2.9-8.9,6.7-17.5,9.5-26.4c4-12.8,7.5-25.8,11.1-38.8c1.6-5.7,2.4-11.7,4.2-17.3
				c1.4-4.4,4-8.4,5.9-12.6c1.3-2.7,2.5-5.4,3.4-8.2c7.2-22.2,14.3-44.4,21.4-66.6c8.3-26,16.6-52.1,25-78.1c0.6-1.9,0.5-4.3,3.1-5.1
				c0.5-0.2,1.2-2.1,1-2.7c-1.7-3.4,0.8-6.1,1.6-9c-0.4,1.9-0.8,3.8-1.2,5.8c0.3,0.1,0.6,0.2,0.9,0.3c0.6-1.2,1.3-2.4,1.7-3.6
				c1.2-3.7,2.2-7.4,3.3-11c0.6-2.1,2.5-4.3-0.1-6.1c3.2-2.8,0.2-7.5,3.7-10.8c-0.3,1.8-0.5,3.2-0.7,4.5c1.6,0.5,5.9-14.5,5.5-19.2
				l0.1,0.2L223.8-492.9L223.8-492.9z M342.3-678.9c2.5-6.4,5-12.7,7.6-19.1C345.3-692.4,343.6-685.7,342.3-678.9
				c-0.5,0.3-1.5,0.4-1.6,0.8c-2.1,6.3-4.1,12.6-6.1,19c0.1,0,0.1,0,0.2,0.1C337.3-665.7,339.8-672.3,342.3-678.9z M415.6-814
				c-2.4,8.1-4.9,16.2-7.3,24.3c3.3-5.6,5.8-11.5,8.1-17.4C417.3-809.5,418-812,415.6-814c2-3.1,3-6.6,3.2-10.3
				C416.4-821.2,415.1-817.8,415.6-814z M359.9-711.9c3.2-4.4,4.8-9.4,4.9-14.9c-1.6,5-3.2,10.1-4.8,15.1c-0.9,0.4-2.2,0.4-2.7,1.1
				c-3.1,4.8-6.4,9.7-4.4,17.2C356.1-700,358.5-705.7,359.9-711.9z M300.1-600.9c1.5-4.4,3.1-8.8,4.6-13.2c0.5-1.4,1-2.8,1.4-4.2
				c0.4-1.5,1.1-2.1,2.4-1.1c0.3-0.6,0.8-1.2,0.8-1.7c-1.1-5.3,1.8-9.9,2.7-14.8c-7,13.6-12.2,28-17.1,42.5c-1,2.9-4.4,4.8-3.8,8.9
				c2.9-3.5,2.9-3.5,3.8-9.1c1.9,0.9,2.4-0.6,3-2c0.8-1.9,1.6-3.7,2.4-5.7c-0.6,2.3-1.2,4.5-1.8,6.8c0.3,0.1,0.6,0.2,1,0.3
				c1.3-2.8,2.6-5.6,3.8-8.5c-0.3-0.2-0.6-0.3-0.9-0.5C301.6-602.3,300.9-601.6,300.1-600.9z M328.7-683.8c-0.7,2.1-1.4,4.3-2,6.4
				c0.2,0,0.5,0.1,0.7,0.1c0.4-2.2,0.9-4.5,1.3-6.8c3,3.2,0.5,7,1.2,10.7c1.2-3.7,2.3-7.3,3.5-11c-3.5-3.8,0.3-6.9,0.8-10.7
				c2.8,1.7,3.8,0.3,4.5-2.1c1.3-4,2.8-8,4.4-12c1.6-3.9,3.5-7.7,5-11.7c1.4-3.5,2.6-7.1,3.6-10.8c0.9-3.3-0.4-7.3,3.4-9.7
				c0.2-0.1,0.2-0.8,0.3-1.2c-4.3-0.6,0.3-4.2-1.9-6c-2.2,5.5-4.3,10.7-6.3,16c-0.6,1.6-2.7,3.9,0.5,5.1c-0.6,1.1-1.7,3.1-1.8,3.1
				c-2.6-1.3-2.7,1.2-3.2,2.5c-3.1,8-6,16-9,24C332-693,330.3-688.4,328.7-683.8z M370.7-765.6c-0.5-0.2-0.9-0.4-1.4-0.6
				c-8.8,22.7-17.8,45.3-26.4,68.1c-4.9,13-9.2,26.2-14,39.2c-4.2,11.4-8.9,22.6-13.1,34c-9.2,24.6-18.3,49.3-27.4,73.9
				c-2.4,6.4-4.9,12.8-7,19.3c-1.9,5.8-3.1,11.8-5.1,17.5c-3.2,9.5-6.8,18.9-9.9,28.4c-1,3.2-2.1,7.2-1.1,9.9c1.5,3.9,1,6.7-0.7,10.2
				c-1.8,3.8-3.5,7.7-4.9,11.7c-0.5,1.3-0.3,2.9-0.4,4.4c0.5,0.2,1.1,0.3,1.6,0.5c1.6-2.8,3.6-5.4,4.8-8.4c3-8,5.4-16.2,8.6-24.1
				c2.3-5.8,3.8-11.7,4.6-17.8c0.4-2.9,1.4-5.8,2.6-8.5c3-6.6,6.7-12.8,9.4-19.5c7.6-19.2,15-38.6,22.2-57.9
				c4.7-12.5,9.1-25.2,13.4-37.8c0.7-2.2,1.1-4.6,1-6.9c-0.2-5.2,0.5-10.1,2.7-14.8c0.8-1.8,1.2-3.8,1.9-5.7c-1.1,0.2-1.7,0.6-1.8,1.1
				c-4,10.6-8.2,21.1-12,31.8c-4.9,13.5-9.4,27-14.3,40.5c-4.7,12.8-9.7,25.5-14.6,38.3c-2,5.2-3.7,10.5-5.5,15.7
				c-4.1,12.4-8.5,24.6-12.1,37.2c-1,3.6-2.5,6.3-5.4,9.7c3.8-11.9,7-22.7,10.7-33.4c6.9-20,14-40,21.1-59.9
				c4.3-12.2,8.9-24.4,13.2-36.6c1.4-3.9,2.4-8,3.9-11.9c4.4-11.8,9-23.5,13.5-35.3c3.5-9.4,6.8-18.8,10.4-28.2
				c3.6-9.6,7.3-19.1,11-28.7c4.2-11,8.4-21.9,12.6-32.9C365.3-750.7,368-758.2,370.7-765.6z M254.5-435.2c-0.3-0.1-0.7-0.1-1-0.2
				c-0.5,0.9-1,1.7-1.3,2.7c-5,15.9-9.9,31.7-14.9,47.6c-4,12.6-8,25.2-11.9,37.8c-0.6,2-1.6,4.1-0.6,6.2c2.5-4.4,4.8-8.6,7.1-12.8
				c2.3-4.3,5-8.4,6.4-13c6.7-21.3,13.1-42.8,19.6-64.2c0.4-1.5,0.7-3,1-4.6c-0.3-0.1-0.5-0.1-0.8-0.2c-1.1,2-2.2,4-3.7,6.7
				C254.4-431.9,254.5-433.6,254.5-435.2z M407.8-873.2c-0.4-0.2-0.8-0.4-1.2-0.6c-1.7,3.6-2.1,8.3-6.2,10.2c-0.3,0.1-0.3,0.8-0.5,1.2
				c-5.7,14-11.3,28.1-17,42.1c-2.4,5.9-4.7,11.8-7,17.8c-0.1,0.3,0.2,0.7,0.3,1.1c0.6-0.4,1.2-0.9,1.7-1.2c0.1,0.8,0.3,1.6,0.6,3.3
				c3.2-6,6.6-10.8,4.8-17c-0.2-0.8,1.1-2.9,1.6-2.9c3.8,0,4.6-3.2,5.6-5.9c5.6-15,11.1-30,16.6-45
				C407.4-871.1,407.6-872.2,407.8-873.2z M213.7-399.9c-0.6,1.1-1.5,2.1-1.8,3.2c-1.5,4.6-2.8,9.2-4.2,13.9c-3,9.6-6.1,19.3-9.2,28.9
				c-2.2,6.8-4.3,13.8-7,20.4c-2.2,5.5-3.9,10.9-4.1,17.1C191.1-322.6,206.7-371.1,213.7-399.9z M374.5-797.4c-0.3-0.1-0.5-0.2-0.8-0.2
				c-0.6,1.3-1.3,2.5-1.9,3.8c-1.4,3.3-4.1,7-3.7,10c0.6,4.3-3.7,4.7-3.9,8.2c-0.3,3.6-2.5,7.1-3.9,10.7c-0.1,0.2,0.3,0.6,0.4,0.9
				c0.5-0.8,1-1.5,1.4-2.3c0.3,0.1,0.6,0.3,0.9,0.4c-1,3.2-2.1,6.5-3.1,9.7c2.2-2.3,3.6-4.9,4.6-7.5c2.6-6.7,4.3-13.7,8.4-19.9
				c2.5-3.9,3.5-8.9,5.1-13.4c-1.7,0.9-2.3,2.3-3.1,3.6c-0.2,0.4-0.9,0.5-1.7,0.9C373.8-794.3,374.1-795.8,374.5-797.4z M157.9-128.8
				c7.7-8.4,9.6-18.3,12-28.2c-1.6,0.5-3.3,1.2-3.7,2.3C163.4-146.4,160.8-138,157.9-128.8z M162.4-236.9c-4.5,14.1-9,28.3-13.5,42.4
				c0.6,0.2,1.2,0.4,1.9,0.6C155-208.1,159.4-222.3,162.4-236.9z M217.4-311.8c-0.3-0.2-0.5-0.5-0.8-0.7c-1.2,1.2-3.1,2.2-3.6,3.7
				c-2.4,6.8-4.5,13.8-6.6,20.7c-0.2,0.7,0.8,1.8,1.5,3.2c2.8-3.8,4.4-7.5,4.3-11.1c-0.1-4.6,1.2-8.2,3.7-11.8
				C216.7-308.9,216.9-310.5,217.4-311.8z M151.7-104.1c4.1-4,8.2-14.9,9.1-23.9c-1.7,1.6-3.7,2.6-4.1,4
				C154.6-117.7,151.3-111.6,151.7-104.1z M167.9-160c4.2-3.7,4.8-8.9,6.9-13.1c2.9-5.7,1-11.3,2.6-16.8C174.2-179.9,171-170,167.9-160
				z M358.5-722.9c5.8-9.8,9.2-20.7,12.6-31.6C365.9-744.4,361.3-734.1,358.5-722.9z M52.4-4.2c0.5-1.1,1.3-2.3,1.4-3.4
				c1-7.3,1.8-14.6,2.6-21.9C52.4-21.5,50.6-13.2,52.4-4.2z M129-301.3c-4.4,8-5.5,17.1-7.9,25.7C124.3-284,129.3-291.8,129-301.3z
				 M324.4-597.7c-6.3,8.7-9.7,18.4-8.3,24.5C319.7-581.8,322.3-589.7,324.4-597.7z M153.5-237.8c5.2-10.1,7.2-18.1,5.7-22.2
				C156.7-252.2,154.1-245.3,153.5-237.8z M335-735.3c0.8,0.3,1.6,0.6,2.3,0.9c2.1-9.5,7.9-17.7,8.2-27.9
				C342-753.3,338.5-744.3,335-735.3z M42.8,64.9c-3.7,5.6-5.9,11.8-5.9,18.7C38.8,77.3,40.8,71.1,42.8,64.9z M131-51.4
				c4.4-3,7.1-10.5,5.8-15.3C133.8-62,132.9-57,131-51.4z M365.3-708.2c-5.4,2.6-3.7,7.4-5.2,10.9C363.8-699.7,364.7-703.5,365.3-708.2
				z M380-619.7c-3.8,3.4-6.9,12.3-6.3,17C376-608.8,378-614.3,380-619.7z M418.4-718.2c-0.4-0.2-0.7-0.4-1.1-0.5
				c-2.6,6.2-5.1,12.4-7.7,18.6c0.4,0.1,0.7,0.3,1.1,0.4C413.3-705.8,415.9-712,418.4-718.2z M431.9-751.7c-0.5-0.2-1-0.4-1.6-0.5
				c-2,4.8-4,9.6-6.1,14.5c0.4,0.2,0.9,0.4,1.3,0.6C427.7-742,429.8-746.9,431.9-751.7z M183.3-302.2c-0.6-0.2-1.1-0.4-1.7-0.7
				c-0.8,3.3-1.7,6.5-2.5,9.8c0.4,0.1,0.8,0.2,1.2,0.3C181.3-296,182.3-299.1,183.3-302.2z M101.7-213.7c0.3,0.1,0.7,0.1,1,0.1
				c1.4-4.5,2.9-9.1,4.3-13.6c-0.3-0.1-0.6-0.2-1-0.3C104.6-222.8,103.2-218.3,101.7-213.7z M482.2-491.4c2.8-3,3-7.7,7.7-7.1
				C485-501.2,485-501.2,482.2-491.4z M527.4-620.1c4.4-1,5.7-3.8,3.4-6.9C529.7-624.7,528.7-622.6,527.4-620.1z M146.2-180.8
				c1.7-3.5,3.2-6.9,2.9-10.8C146.2-188.4,146.2-184.6,146.2-180.8z M68.4-15.1c-0.4-0.2-0.8-0.3-1.2-0.5c-0.7,2.3-1.5,4.6-2.6,8.2
				C67.8-10.1,67.7-12.8,68.4-15.1z M60,9c-3.3,5.8-3.3,5.8-2.5,10.2C58.5,15.1,59.2,12,60,9z M49.5,38.6c0.3,0.1,0.5,0.3,0.8,0.4
				c1.7-5.4,3.4-10.7,5.1-16.1c-0.3-0.1-0.6-0.2-0.8-0.3C52.8,28,51.2,33.3,49.5,38.6z M120.6-271c-0.4-0.1-0.9-0.2-1.3-0.3
				c-0.8,2.5-1.6,4.9-2.5,7.9C119.7-265.7,121-268,120.6-271z M367.9-715.2c2.3-2.4,2.3-2.4,2.7-6C366.8-719.8,366.8-719.8,367.9-715.2
				z M386.3-641.3c4-5,5-8.5,3.7-11.6C388.7-648.8,387.5-645,386.3-641.3z M341.1-640.7c-5,1.2-5.3,1.7-3.9,6.6
				C338.6-636.5,339.8-638.6,341.1-640.7z M631.2-903.8c-0.3-0.1-0.6-0.2-0.9-0.3c-0.7,2.1-1.3,4.2-2,6.2c0.3,0.1,0.6,0.2,0.9,0.3
				C629.9-899.7,630.5-901.7,631.2-903.8z M507.1-547.6c-0.9-2.2,0.1-4.8-2.5-6.1c-0.3,2.6-0.6,4.7-0.9,7.1
				C504.9-547,505.6-547.1,507.1-547.6z M585.4-672c-1.5,0.3-2.8,0.5-4.2,0.8C583.8-668.7,583.8-668.7,585.4-672z M460.5-418.4
				c-0.5-0.4-1.1-0.9-1.6-1.3c-0.2,2.1-3.4,2.1-2.1,6.3C458.3-415.5,459.4-416.9,460.5-418.4z M392.2-772.7c-0.4-0.2-0.8-0.4-1.2-0.6
				c-1,3.2-2,6.4-3,9.6c0.2,0.1,0.4,0.1,0.6,0.2C389.7-766.6,390.9-769.6,392.2-772.7z M380.3-745.9c0.3,0.1,0.6,0.3,0.9,0.4
				c0.6-1.5,1.3-2.9,1.8-4.4c0.4-1.3,0.7-2.6,1-4c-0.2-0.1-0.4-0.1-0.7-0.2C382.3-751.3,381.3-748.6,380.3-745.9z M400.5-769.5
				c-0.2-0.1-0.5-0.2-0.7-0.2c-0.9,2.7-1.7,5.4-2.6,8.1c0.2,0.1,0.5,0.2,0.7,0.2C398.8-764.1,399.7-766.8,400.5-769.5z M330.1-611.2
				c-0.3-0.4-0.7-0.7-1-1.1c-1.3,1.5-2.7,2.9-4.6,5.1C329.1-606.4,329.1-609.4,330.1-611.2z M381-792.1c-0.1-0.1-0.2-0.3-0.3-0.4
				c-0.9,2.7-1.7,5.4-2.6,8.1c0.1,0,0.3,0.1,0.4,0.1C379.3-786.9,380.1-789.5,381-792.1z M389.2-782.3c-0.4-0.3-0.8-0.5-1.2-0.8
				c-0.8,1.9-1.5,3.8-2.3,5.7c0.3,0.2,0.7,0.3,1,0.5C387.6-778.8,388.4-780.5,389.2-782.3z M372.2-742.8c0.2,0.1,0.4,0.1,0.6,0.2
				c0.7-2.3,1.5-4.6,2.2-6.9c-0.2-0.1-0.4-0.1-0.6-0.2C373.7-747.4,372.9-745.1,372.2-742.8z M313.9-641.9c0.1,0,0.3,0.1,0.4,0.1
				c0.9-2.3,1.7-4.6,2.6-6.9c-0.4-0.1-0.8-0.2-1.2-0.4C315.1-646.7,314.5-644.3,313.9-641.9z M409.9-881.4c2.7-4,2.7-4,2.6-8.2
				C411.5-886.4,410.7-883.9,409.9-881.4z M435.6-761.2c-2.2,1.7-2.2,1.7-2.3,4.9C434.3-758.4,435-759.8,435.6-761.2z M178.3-285.8
				c-0.2-0.1-0.5-0.3-0.7-0.4c-0.5,1.7-1.1,3.4-1.6,5.1c0.3,0.1,0.5,0.2,0.8,0.3C177.3-282.5,177.8-284.2,178.3-285.8z M499.4-535.8
				c1.3-2,2-3.2,2.8-4.3C500.4-539.7,498.3-539.7,499.4-535.8z M491-520.3c0,0.3,0.1,0.6,0.1,0.9c1.3-0.1,2.7-0.1,4-0.2
				c0-0.5-0.1-1-0.1-1.5C493.6-520.8,492.3-520.5,491-520.3z M592.8-686.7c0-0.4-0.1-0.8-0.1-1.3c-1.2,0.2-2.4,0.4-3.5,0.6
				c0,0.4,0.1,0.7,0.1,1.1C590.4-686.3,591.6-686.5,592.8-686.7z M579.7-661.4c-1.1,0.7-1.8,1-2.2,1.6c-0.1,0.2,0.3,1.3,0.5,1.3
				c0.5,0,1.2-0.2,1.6-0.7C579.7-659.5,579.6-660.2,579.7-661.4z M256.9-442c0.2,0.2,0.5,0.4,0.7,0.5c0.4-0.5,1.1-0.8,1.3-1.4
				c0.3-0.7,0.3-1.6,0.4-2.3c-0.3-0.1-0.6-0.1-1-0.2C257.9-444.2,257.4-443.1,256.9-442z M545.7-669.5c-0.1-0.5-0.1-1-0.2-1.5
				c-1,0.1-2,0.3-3,0.4c0.1,0.6,0.2,1.6,0.4,1.6C543.8-669.1,544.7-669.3,545.7-669.5z M290.2-569.7c0.4,0.2,0.8,0.3,1.2,0.5
				c0.3-1.2,0.6-2.4,0.8-3.6c-0.3-0.1-0.7-0.2-1-0.3C291-572,290.6-570.9,290.2-569.7z M391.8-748.6c0.2,0.1,0.4,0.1,0.6,0.2
				c0.5-1.5,0.9-3,1.4-4.5c-0.2-0.1-0.4-0.1-0.6-0.2C392.7-751.6,392.3-750.1,391.8-748.6z M386.1-735.2c0.3,0.1,0.5,0.2,0.8,0.4
				c0.5-1.2,1.1-2.4,1.6-3.5c-0.3-0.1-0.6-0.3-0.9-0.4C387.1-737.6,386.6-736.4,386.1-735.2z M371.9-726.4c0.3,0.1,0.6,0.3,0.9,0.4
				c0.4-1.3,0.8-2.6,1.2-3.9c-0.3-0.1-0.5-0.2-0.8-0.2C372.8-728.9,372.4-727.6,371.9-726.4z M256.3-584.2c0.2,0.1,0.4,0.2,0.6,0.2
				c0.3-1.1,0.7-2.1,1-3.2c-0.1-0.1-0.3-0.1-0.4-0.2C257.1-586.3,256.7-585.2,256.3-584.2z M271.4-623.3c0.2,0.1,0.4,0.1,0.6,0.2
				c0.2-0.8,0.3-1.6,0.5-2.4c-0.1,0-0.3-0.1-0.4-0.1C271.8-624.8,271.6-624,271.4-623.3z M378.3-150.3c-0.4-0.7-0.6-1.4-1-1.6
				c-0.2-0.1-1.2,0.4-1.2,0.8c-0.1,0.5,0.3,1.3,0.6,1.5C377-149.5,377.7-150,378.3-150.3z M566.5-672.3c0.3,0.4,0.5,0.9,0.6,0.9
				c0.5-0.2,1-0.5,1.5-0.7c-0.2-0.4-0.5-1-0.7-1C567.4-673,567-672.6,566.5-672.3z M593.8-699.7c-0.1-0.2-0.3-0.5-0.4-0.7
				c-0.3,0.2-0.8,0.2-1,0.5c-0.3,0.6-0.4,1.3-0.6,1.9C592.4-698.5,593.1-699.1,593.8-699.7z"/>
            </g>
        </g>

        <g clip-path="url(#clipFlower2)">
            <g class="a-fill-inverse filler">
                <path d="M-61.5,294.7c1.8-1,1.9-2.5,1-4c-2.8-4.4-2.6-10.3-7-13.9c-0.2-0.2-0.3-0.6-0.4-1c-1.3-11.5-7.1-21.7-10.5-32.5
				c-1.4-4.5-2.2-9.2-3.8-13.6c-3.2-8.9-6.9-17.7-10.2-26.5c-2-5.4-3.8-10.9-5.8-16.4c-0.3-0.8-0.8-1.5-1.8-3.2
				c-1.6,6.5,2,11.3,1.6,16.6c-3.9-11.6-7.7-23.3-11.7-35.3c1.1,1.3,1.9,2.3,3.6,4.2c0.5-5.5-2.4-8.9-3.2-12.8
				c-0.9-4.1-2.2-8.1-3.4-12.2c-1.3-4-2.8-7.9-4.2-11.9c-1.4-4-2.8-8-4.2-12c-1.4-4-2.6-8-4-12c-1.3-4-2.6-8-4-12s-3.1-7.9-4.5-11.9
				c-1.4-3.9-2.7-7.9-3.6-12.1c2,1.4,2.5,3.7,3.5,5.7c1.3,2.7,2.6,5.4,3.8,8.1c1,2.3,4.6,1.6,4.8,5.1c0.1,2.3,1.6,4.6,2.5,6.9
				c0.4-0.1,0.7-0.3,1.1-0.4c-2.2-5.8-4.6-11.6-6.6-17.5c-1.9-5.6-3.2-11.4-4.8-17.1c-0.2-0.7-0.3-1.4-0.6-2
				c-5.5-9.8-7.6-20.8-11.2-31.2c-4.5-12.8-8.4-25.7-13.2-38.4c-4.9-13.2-10.5-26-15.8-39.1c-1.7-4.2-3.1-8.6-4.8-12.8
				c-2-5.1-4.2-10.1-6.3-15.1c-1.1-2.6-2.3-5.1-3.2-7.8c-4.5-13.1-8.8-26.3-12.8-38.1c1.8,3.3,4.2,7.9,6.6,12.5
				c0.4-0.1,0.9-0.3,1.3-0.4c-0.3-1.4-0.5-2.7-0.9-4.1c-0.4-1.3-1-2.6-1.5-4.1c0.9,0.1,1.6,0.2,2.6,0.3c-6.7-16-13.3-31.9-19.9-47.7
				c0.2-0.1,0.5-0.3,0.7-0.4c0.6,1,1.2,1.9,1.9,2.9c0.2-0.2,0.5-0.5,0.4-0.7c-4.2-9.7-8.4-19.3-12.6-29c-2.3-5.3-4.3-10.7-6.6-16
				c-1.5-3.4-3.1-6.7-4.9-9.9c-1.2-2.1-0.8-3.9,0.7-6.4c0.9,2,1.4,3.4,2.1,4.7c0.9,1.7,1.6,3.6,2.8,5.1c10.2,11.9,14.5,26.8,21,40.5
				c0.8,1.6,1.3,3.3,2.2,4.8c0.4,0.7,1.6,0.9,3.7,1.9c-6.6-19.7-14.9-37.4-23.4-55.8c1.4,0.5,2.1,0.6,2.3,0.9c2.3,4.7,4.6,9.5,6.8,14.3
				c5.4,11.5,10.7,23,16.1,34.4c0.8,1.8,0.9,4.3,3.9,4.2c0.3,0,0.7,0.7,0.9,1.2c3.6,7.2,7.2,14.4,10.7,21.7c3.7,7.8,7.2,15.7,10.8,23.5
				c0.6,1.2,1.4,2.3,2.5,4.3c0.5-3-0.6-4.7-1.4-6.5c-2.7-6.3-5.7-12.5-8.2-18.9c-3.6-9.1-6.8-18.4-10.2-27.6c-1.2-3.3-2.7-6.5-4.1-9.7
				c-2.4-5.5-3.7-11.2-3.7-17.4c2.2,4.1,4.7,8.2,6.7,12.4c6.3,13,12.3,26.1,18.5,39.1c5.6,11.5,11.4,22.9,17,34.5
				c1.8,3.6,3.1,7.5,4.7,11.2c0.7,1.5,1.5,3,3.1,4.1c-0.1-0.6,0-1.2-0.2-1.7c-8.4-15.7-14.2-32.5-20.8-49c-6.6-16.5-13.1-33-20.1-49.3
				c-6-13.7-13.1-26.9-18.9-40.6c-2.8-6.7-6-13.3-7.4-20.7c-1-5.6-4.1-10.8-6.4-16.1c-1.1-2.4-2.1-4.6-1.5-7.5c0.4-1.9-0.2-4.3-0.9-6.2
				c-1.7-4.4-0.7-8.5,0.1-13.9c1.4,1.8,2.6,2.8,3.2,4.1c4.5,9.5,9,19.1,13.2,28.7c5.3,12,10.3,24.2,15.4,36.3c0.5-0.1,0.9-0.3,1.4-0.4
				c-0.4-1.7-0.6-3.5-1.3-5.1c-5.2-13.5-10.3-27-15.7-40.3c-2.6-6.3-6.1-12.3-8.6-18.6c-4.2-10.2-7.9-20.6-12-30.8
				c0.2,0.3,0.4,0.6,0.5,0.9c3,10.4,8.9,19.4,13.8,28.9c4.2,8.2,7.9,16.8,11.7,25.2c6.5,14.4,13,28.9,19.5,43.3
				c1.6,3.6,3.4,7.1,5.1,10.7c0.3-0.1,0.6-0.3,0.9-0.4c-1.8-4.7-3.6-9.3-5.3-14c-1.9-5.1-5.2-9.8-4.8-15.7c0.1-1.2-0.3-2.5-0.8-3.6
				c-2.9-7-6.3-13.8-8.6-20.9c-2.9-8.9-7.7-16.9-11.5-25.4c-0.3-0.6-0.1-1.5,0.3-2.3c13,22.3,20.9,46.8,32.1,69.8
				c0.4-0.1,0.7-0.2,1.1-0.3c-0.6-3.6-1-7.3-2-10.8c-1.4-4.9-3.4-9.6-4.8-14.5c-1.5-5.3-2.5-10.8-4-16.1c-0.9-3-2.5-5.8-3.7-8.7
				c-0.8-1.9-1.5-3.9-1.5-6.2c0.9,1.9,1.8,3.8,2.8,5.6c2.7,4.7,5.9,9.1,8.3,14c3.3,6.6,6.3,13.4,8.8,20.3c6.3,17.2,14.5,33.6,22,50.3
				c1.5,3.4,3,6.8,4.5,10.1c0.3,0.7,0.9,1.3,1.3,2c0.3-0.1,0.7-0.3,1-0.4c-0.9-2.6-1.7-5.3-2.8-7.9c-5.1-11.6-10.1-23.2-15.3-34.7
				c-3.8-8.5-8.1-16.8-11.8-25.3c-3-6.9-5.4-14.1-8.4-21c-5-11.4-10.4-22.7-15.6-34c-0.6-1.4-1.1-2.9-1-4.7c0.6,0.9,1.5,1.6,1.8,2.6
				c2.9,8.5,8.3,15.8,12.2,23.8c2.4,4.9,4.2,10.1,6.3,15.1c1.7,4.1,3.4,8.1,5.1,12.1c2.4,5.8,4.7,11.7,7.1,17.5
				c0.7,1.7,1.6,3.4,3.1,4.9c-0.1-0.8-0.2-1.6-0.5-2.3c-5.4-13.4-10.7-26.8-16.2-40.2c-0.7-1.7-1.4-3-0.9-4.9c0.3-1.3-0.4-3-0.9-4.5
				c-1.3-3.8-2.7-7.5-4.1-11.3c-2.5-6.7-5.1-13.5-7.8-20.6c3.1-0.9,5.3-2.5,5.5-5.3c0.2-2.2,0-4.5-0.7-6.6c-3-10.1-6.2-20.1-9.5-30.2
				c-0.6-2-1.4-4-2.4-5.9c-5.6-11.3-11.3-22.6-16.9-33.9c-0.1-0.3,0-0.7,0.1-1.6c3.5,3.6,5.4,7.8,7.6,11.7c3.7,6.7,7.6,13.4,10.8,20.5
				c3.2,7.2,6,14.7,8.4,22.2c2,6.4,3.8,12.8,7,18.7c1.6,3,2.2,6.6,3.3,9.9c1.2,3.6,2.3,7,5.4,9.8c1.9,1.7,2.4,5,3.4,7.6
				c3.2,8.2,7.3,15.9,11.8,23.5c2.7,4.5,4.1,9.7,6.2,14.5c1.5,3.4,1.5,7.6,5.6,9.6c0.6,0.3,0.8,1.5,1.1,2.3
				c7.7,17.4,15.4,34.7,23.1,52.1c1.7,3.8,3.2,7.7,5.1,11.5c0.6,1.2,2.1,2.1,3.9,2.8c-1.2-4.1-2.6-8.2-3.7-12.4
				c-5-18-14.6-34.1-21.8-51.2c-2.4-5.8-4.2-11.9-6.3-17.8c-5.8-16.6-14-32.2-21.3-48.2c-1.9-4.1-3.8-8.2-4.6-12.7
				c0.3-0.1,0.7-0.3,1-0.4c0.4,1.1,0.9,2.3,1.3,3.4c0.5,1.3,0.9,2.7,1.4,4c4.5,12.3,10.1,24.1,17.9,34.6c5.8,7.9,8,17.1,12,25.6
				c3.1,6.5,5.6,13.2,8.5,20.4c1.4-3.1,1.4-3.1,0.4-5.5c-3.9-9.2-7.8-18.5-11.6-27.7c-1.6-3.7-3.1-7.5-4.8-11.1c-1.9-4.1-4-8-2.6-12.9
				c0.4-1.5-0.1-3.5-0.6-5.2c-4.1-12.9-8.4-25.8-12.6-38.6c-0.7-2.1-1.6-4.2-2-6.7c0.5,0.6,1.2,1.2,1.5,2c1.4,3.2,2.5,6.6,3.9,9.8
				c1.3,2.9,1.2,6.5,5.1,8.1c1.2,0.5,1.6,3,2.2,4.6c1.7,4.6,3.5,9.1,6.5,13.1c2.4,3.2,3.3,7.3,4.9,11.1c5.9,14.1,11.9,28.2,17.8,42.3
				c2.4,5.8,4.6,11.8,7.2,17.6c4.3,9.8,9.8,18.9,15.9,27.7c-0.5-1.4-1-2.9-1.6-4.3c-3.5-7.7-7.2-15.3-10.4-23.1
				c-4.5-11-8.9-22.2-12.9-33.4c-0.9-2.5,0-5.6,0.1-9.1c2,3.7,3.6,6.7,5.4,10.1c2.5-2.7,0.2-5,0.4-7.8c1,1.6,1.7,2.8,1.8,3.1
				c-1.8-4.6-3.7-10.3-6-15.9c-4.2-10.3-6.6-21-8.8-31.8c-0.3-1.6-0.6-3.1,1.5-4.6c1.1,2.8,2.1,5.3,3,7.9c3.8,11.9,8.6,23.4,14,34.6
				c2.9,6.2,5.2,12.6,7.7,19c1.5,3.7,3,7.5,4.6,11.2c4.7,10.8,9.2,21.7,14.1,32.4c4.3,9.6,8.9,19.1,13.4,28.7c0.3,0.6,0.8,1.2,1,1.9
				c2.4,11.3,8,21.4,12.6,31.8c3.7,8.3,6.7,16.9,11.6,24.7c5,8,8.8,16.8,12.8,25.4c4.4,9.6,8.5,19.3,13.2,28.8
				c-3.5-9.3-7-18.6-10.6-27.9c-2.2-5.7-4.7-11.4-7.2-17c-4-9-8.2-17.9-12.1-26.9c-3-6.9-5.8-14-8.7-21c-8.2-20-16.8-39.9-26.2-59.4
				c-3.5-7.3-6.2-15-9.3-22.4c-5.4-12.8-11-25.5-16.3-38.4c-2.1-5-3.4-10.3-5.3-15.4c-1.7-4.6-3.5-9.2-5.3-13.8
				c-3.1-8.2-6.3-16.4-9.2-24.7c-5.3-15.3-10.9-30.5-18.5-44.8c-2.4-4.5-3.9-9.4-6-14c-2.9-6-6-11.9-9-17.9c1.3-0.5,2.6-1,4-1.5
				c3.8,7.5,6.8,15.4,11.9,22.3c-3-7.8-5.9-15.6-8.9-23.4c0.3-0.1,0.7-0.2,1-0.4c8.2,19.6,15.9,39.4,27.1,57.6c0.5,0.8,1.3,1.4,1.9,2.1
				c0.5-2,0.3-3.6-0.4-5.1c-3.4-7.5-6.9-15-10.4-22.5c-1.8-3.8-4.6-7.5-5.1-11.5c-0.4-4.2-1.8-7.7-3.3-11.4c-1.6-3.7-2.6-7.6-3.9-11.5
				c1.6-0.6,3.3-1.2,4.9-1.9c5.3,13.2,12.4,25.6,18.8,38.3c6.9,13.8,13.9,27.6,18.4,42.5c2.7,8.9,6.1,17.6,9.2,26.4
				c0.6,1.6,1.5,3.2,2.3,4.7s1.7,3,3,5.3c-2.1-9-3.7-17.1-6-24.9c-2.5-8.4-5.1-16.8-8.6-24.8c-5.1-11.8-11.1-23.1-16.4-34.8
				c-5.2-11.5-9.9-23.3-14.8-34.9c2-0.7,4-1.5,5.9-2.2c3.7,8.9,7.3,17.9,11.3,26.7c4.2,9.1,9.2,17.8,13.3,27
				c6.8,15.1,13.9,30,18.8,45.9c2.5,8.1,5.2,16.1,8.4,23.9c2.6,6.5,6.1,12.6,8.9,19c7.7,18,15.1,36,22.9,54c3.8,8.7,8.1,17.1,12.2,25.7
				c4.5,9.5,8.9,19.1,13.7,28.6c-0.2-1.3-0.3-2.6-0.6-4.6c1.5,3.1,2.6,5.5,3.8,7.9c-5.5-20-15.5-38.1-23.2-57.5
				c1.4,0.1,2.5,0.1,4.5,0.2c-4.9-11.1-9.8-21.6-14.1-32.2c-4.4-10.7-8.3-21.6-12.4-32.4c2.2-0.7,2.5,1.2,3.1,2.5
				c3.9,9.1,7.5,18.2,11.5,27.2c2.8,6.2,6.2,12.2,9.1,18.3c1.5,3,2.6,6.2,4,9.2c4.5,9.7,9,19.4,13.7,29c3.4,6.9,7.2,13.5,10.7,20.3
				c0.8,1.5,1.4,3.1,2.3,4.6c-1.6-7.6-6.2-14.4-7-22.2c0.5-0.2,0.9-0.3,1.4-0.5c3.9,8.4,7.8,16.8,11.6,25.1
				c5.9,12.9,11.9,25.9,17.8,38.8c2.1,4.5,4.2,8.9,6.2,13.4c4.4,10,8.7,20,13.1,30c0.3,0.8,0.7,1.8,1.3,2.3c5.2,3.9,6.3,10,8.8,15.4
				c3.8,8.1,7.5,16.3,11.1,24.5c1.6,3.5,2.8,7.2,4.2,10.7c5.1,12.9,9.9,26,15.2,38.8C77.8-7.9,83.6,5.3,89.6,18.4
				c1.5,3.3,3.9,6.2,6.3,9c0.8-3.6-1.2-6.2-2.7-8.9c-0.7-1.3-2-2.1-1.2-4.1c0.4-1.1-0.8-2.9-1.3-4.4C89.5,6.5,88.2,3.1,87-0.4
				c-1.7-5-3.5-9.9-5.1-15c-2.5-7.7-4.7-15.4-7.3-23.1c-2.7-8-5.6-15.9-8.8-23.8C59.2-78.6,52.8-95,45.6-111c-3-6.5-5.7-13.1-8.4-19.7
				c-4.6-11-10-21.8-13.3-33.2c-5.3-17.7-12.5-34.6-20-51.3c-5.5-12.2-11.1-24.3-16.4-36.6c-5.5-12.6-10.6-25.3-15.9-38
				c-0.7-1.8-1.8-3.4-2.6-5.2c-0.4-0.8-0.5-1.8-0.7-2.7c0.3-0.1,0.7-0.3,1-0.4c0.5,1.2,0.9,2.4,1.4,3.6c4.8,11.5,9.3,23.1,14.4,34.5
				c4.4,9.8,9.5,19.2,14.1,28.9c4.2,8.9,8,18,12,27c8.4,18.6,16.9,37.1,25.3,55.7c2.8,6.2,5.5,12.5,8.3,18.8c6.7,15.5,13.5,31,20,46.6
				C71.6-66.4,78-49.7,84.7-33.1c6.5,16,13.1,31.9,19.7,47.8c0.6,1.5,1.5,2.9,2.3,4.3c0.4-0.2,0.8-0.4,1.1-0.6
				c-7-18.6-14.1-37.1-21.1-55.7c0.3-0.1,0.5-0.2,0.8-0.3c0.6,1,1.3,2,1.7,3.1C94.5-21.3,99.6-8,105,5.2c2.7,6.7,5.8,13.2,9,19.7
				c0.9,1.9,2.6,3.4,4.5,4.7C110.7,9,102.8-11.7,95-32.3c0.3-0.2,0.7-0.3,1-0.5c0.4,0.8,0.9,1.6,1.3,2.5c5.7,12.9,11,26,17.3,38.5
				c3.4,6.7,7.8,12.9,10.8,19.9c4.3,10,8.8,19.9,13.3,29.9c0.7,1.6,1.4,3.2,2.4,4.7c0-4.6-1.9-8.8-3.6-12.9c-3.6-8.4-7.5-16.7-11.3-25
				c-6.1-13.4-12.4-26.8-18.4-40.3c-2.6-5.8-4.7-11.8-6.9-17.7c-2.8-7.2-5.5-14.4-7.7-21.8c3.4,7.3,7,14.6,10.1,22
				c3.4,7.9,6.1,16.1,9.5,24c3.1,7.2,6.8,14.2,10.2,21.2c1.7,3.6,3.5,7.3,5.1,11c2.5,5.8,4.9,11.7,7.4,17.5c1.5,3.5,3.1,7,5.2,10.4
				c-1-2.7-2.1-5.4-3.1-8.2c0.2-0.1,0.3-0.1,0.5-0.2c2.1,4.2,4.2,8.3,6.2,12.5c0.3,0.5-0.3,1.4-0.4,2.1c-0.1,0.6-0.3,1.4,0,1.9
				c3,6.5,6.1,13,9.2,19.5c3.5,7.5,6.8,15,10.2,22.5c1,2.1,2.2,4.1,3.4,6.2c0.4-0.2,0.7-0.4,1.1-0.5c-1.5-4-2.9-8.1-4.6-12
				c-6.5-15.2-13.1-30.4-19.7-45.5c-4.8-10.9-10.1-21.7-14.4-32.8c-2.9-7.5-7.4-13.9-10.9-21c-2.6-5.3-5.1-10.8-5.5-16.9
				c-0.2-2.5-1.5-5-2.4-7.5c-5.8-15.7-11.6-31.3-17.5-47c-0.4-1.2-0.8-2.3-0.5-3.7c3.5,9.3,7,18.7,10.6,27.9c5.6,14.3,11,28.6,17,42.7
				c3.6,8.5,8.4,16.6,12.5,24.9c1.2,2.4,1.7,5,2.8,7.4c0.9,2,2,3.9,3.4,6.3c0.6-2.2,1-3.7,1.4-5.2c20.5,36.4,36.3,75,54.7,112.4
				c-1.2-3.2-2.5-6.5-3.7-9.7c-1.3-3.3-2.4-6.6-3.8-9.8c-1.3-3.1-2.9-6.1-4-9.2c-1.2-3.1-3.4-5.8-3.5-9.2c0.4-0.2,0.7-0.3,1.1-0.5
				c0.6,0.7,1.3,1.3,1.7,2.2c5,11.1,10.1,22.1,14.8,33.2c7.7,17.9,15,36,22.6,53.9c5.1,12.1,10.5,24.1,15.8,36.2
				c0.4,0.8,0.5,1.8,1.2,2.3c4.5,3.8,5.7,9.5,8.7,14.3c-6.6-16.4-13.2-32.9-19.8-49.3c-2.9-7.2-6.1-14.2-9-21.4
				c-1.9-4.7-3.3-9.6-5.1-14.3c-3-8-6.3-15.9-9.3-24c-1.2-3.3-1.8-6.7-2.9-10.7c2.7,5.7,5.1,10.9,7.5,16c0.2-0.1,0.4-0.2,0.6-0.3
				c0-3-0.1-5.9-0.1-8.9c0.3-0.1,0.7-0.2,1-0.3c0.5,0.6,1.1,1.1,1.4,1.7c4.5,9.7,8.5,19.7,13.4,29.2c3.6,6.7,5.1,14.1,8.3,21
				c5.4,11.3,10,22.9,14.7,34.4c5.4,13.2,10.4,26.5,15.8,39.7c3.4,8.3,7,16.5,10.4,24.8c4.4,10.7,8.7,21.4,13.1,32.2
				c3.3,8,6.7,15.9,9.9,23.8c4.8,11.9,9.4,23.9,14.2,35.8c1,2.5,2.6,4.7,4.5,6.7c-4.5-14.7-10.4-28.8-16.3-43
				c-5.8-14-11.1-28.2-15.9-42.8c0.6,0.9,1.3,1.7,1.7,2.7c3.1,7.4,6.2,14.9,9.2,22.3c2.3,5.7,4.6,11.4,6.8,17.2c0.6,1.5,0.7,3,1.1,4.6
				c0.2,1,0.2,2.2,0.7,3c4.2,5.7,6.1,12.4,8.7,18.8c4.9,12,9.5,24.1,14.1,36.2c6.6,17.2,13.1,34.5,19.8,51.6c2.3,5.9,5.7,11.4,7.7,17.3
				c10.2,30.5,20.2,61.1,30.2,91.8c2.7,8.4,5.2,16.8,7.3,25.3c0.9,3.5,0.6,7.3,0.9,10.9c0.2,2.2-0.1,4.6,0.5,6.7
				c3.9,14.3,8,28.5,11.9,42.7c1.8,6.6,3.4,13.2,4.1,20.1c0.6,5.7,3,11.2,4.3,16.8c5.9,26.4,11.4,52.9,17.7,79.3
				c4,16.6,9,32.9,13.6,49.3c1.8,6.6,3.6,13.2,5.7,19.8c1.5,4.8,3.4,9.6,5.4,14.3c5.1,12.7,10.4,25.4,15.5,38.1
				c6.6,16.5,13.2,33,19.5,49.6c2.1,5.5,3.1,11.5,4.6,17.3c0.7,2.5-0.1,4.4-1.9,6.5c-1.7-4.4-3.5-8.3-4.6-12.4
				c-3.1-11.6-7.9-22.5-12.7-33.5c-6.9-15.8-13.2-31.8-20-47.7c-2.8-6.6-6.2-13-9-19.7c-2.5-6-4.7-12.1-6.8-18.3
				c-2.4-7.2-4.4-14.4-6.6-21.6c-3-9.9-6.2-19.8-9.1-29.7c-3-10.3-5.7-20.6-8.5-31c-0.3-1-0.5-2.3-1.1-2.9c-2.8-2.7-4-6.2-5.4-9.8
				c2.7,22,7.8,43.4,14.2,65.5c-4.2-2.6-4.1-6.9-6.9-9.5c2.2,7.6,4.4,15.1,6.5,22.7c1.8,6.1,3.7,12.1,5.3,18.2
				c4.1,15.6,10.8,30.2,16.8,45c7.9,19.6,16.3,38.9,24.2,58.5c2.6,6.6,4.2,13.6,5.6,20.7c-3-7.9-5.7-15.8-9-23.6
				c-7.1-17-14.4-34-21.8-50.9c-1.2-2.7-3.3-5.1-4.5-7.8c-4-8.6-8-17.2-11.4-26c-1.8-4.5-2.2-9.6-3.7-14.2c-1.9-5.6-4.7-10.9-6.6-16.6
				c-3.4-10.1-6.5-20.4-9.7-30.6c-0.4-1.3-1-2.6-2.2-3.7c0.4,2.8,0.5,5.7,1.1,8.4c1.6,7,3.2,14,5.1,21c3.1,11.5,6.1,23.1,9.8,34.5
				c3.1,9.7,6.9,19.2,10.8,28.7c7.8,19,16.1,37.9,23.7,57c6.3,15.9,11.3,32.2,13.1,49.3c0.1,0.9,0.1,1.7,0.1,2.6c0,0.2-0.4,0.4-1.3,1.3
				c-0.8-4.1-1.6-7.8-2.3-11.5c-0.4,0-0.8,0.1-1.2,0.1c0,3.5,0.1,7,0.1,11.1c-2.4-0.8-4.2-1.4-6.1-2c-0.5,0.8-1.1,1.7-1.8,2.9
				c-0.7-2.6-1.4-5-2-7.4c-0.3,0-0.6,0-0.9,0c-0.3,0.8-0.7,1.7-1,2.6c-0.2-0.1-0.6-0.2-0.7-0.4c-0.5-1.5-0.9-3-1.3-4.6
				c-1.7-5.8-3.2-11.5-7.3-16.5c-3.3-4-4.3-9.9-6.4-14.9c-4.7-11.6-9.4-23.1-14.3-34.6c-6.8-16.1-13.8-32-20.4-48.1
				c-3.9-9.4-7.7-18.9-10-28.9c-0.1-0.5-0.5-0.9-1.4-2.4c0.2,2.4,0.1,3.6,0.5,4.6c4.1,12.2,7.7,24.5,13,36.2
				c6.6,14.6,12.3,29.6,18.4,44.3c4.8,11.7,9.8,23.4,14.6,35.1c4.3,10.5,6.3,21.6,7.5,32.8c0.4,4.3-0.6,8.5-5.6,11
				c-2.5-18.7-8.1-36.3-15.7-53.3c-0.6,9.2,5.5,16.8,6.2,26.4c-6.4-16.8-12.5-33-18.7-49.4c1.2,2,2.4,4,3.9,6.6
				c1.5-2.9,0.3-4.7-0.5-6.5c-3.7-8.9-7.6-17.8-11.2-26.8c-6.3-15.8-12.6-31.7-18.4-47.7c-4-11.1-7.2-22.4-10.8-33.6
				c-0.4-1.3-1-2.6-1.8-3.7c1.7,12.9,6.1,25.2,9.8,37.6c3.8,12.6,8.4,25,12.6,37.4c-0.4-0.2-1-0.4-2.1-0.9c-0.8,3.7,1.5,6.3,2.6,9.3
				s2.7,5.7,3.9,8.7c1.3,3,2.3,6.1,3.1,9.3c-3.8-4.8-5-10.9-7.6-16.3c-2.6-5.5-5-11-8.3-16.2c0.3,1.7,0.5,3.4,1.1,5
				c1.6,4.6,3.3,9.3,5.2,13.8c5.9,14.1,11.7,28.3,18.1,42.2c6,12.8,9.9,26.3,14.4,39.6c2.1,6.3,3.3,12.8,1.8,19.6
				c-0.5,2.2,0.1,4.7,0.3,7.1c-0.8,0-1.3,0.1-1.4-0.1c-4.1-9.3-7.3-18.9-9.6-28.9c-1.7-7.3-4.4-14.5-7.2-21.5
				c-10.9-26.5-22.1-52.8-33.1-79.2c-3.7-8.8-7.2-17.6-10.5-26.5c-2.6-7.1-4.4-14.4-7-21.5c-2.9-8.1-6.2-16-9.3-24
				c-0.2-0.4-0.7-0.7-1.7-0.7c2.3,8.8,4.5,17.7,6.8,26.5c0.9,3.6,3.1,7.2,2.7,10.6c-0.6,4.9,1.6,9.1,1.7,13.8c0.1,3.2,1.4,6.4,2.1,9.7
				c0.6,2.7,0.9,5.5,1.4,8.2c0.4,2.1,0.7,4.2,1.4,6.1c4.3,11.5,8.8,23,13.2,34.5c0.5,1.3,0.8,2.6,0.6,4.2c-0.6-1.4-1.2-2.7-1.8-4.1
				c-3.3-7.4-6.6-14.7-9.7-22.2c-3.8-9.2-7.6-18.6-11.2-27.9c-2.7-7-5.4-14.1-7.7-21.3c-1.8-5.4-3-11-4.4-16.5
				c-2.7-10.4-5.3-20.8-8-31.1c-1-3.7-2.2-7.2-5.5-9.9c1.4,5.8,2.8,11.7,4.4,17.5c3.4,12.2,6.4,24.5,10.7,36.3c1.8,5,2.9,10.1,4.4,15.2
				c3.3,11.5,7.2,22.8,12.1,33.7c7.5,16.7,14.1,33.7,21,50.7c4.6,11.2,9.4,22.4,13.4,33.8c3.3,9.2,5.5,18.9,8.2,28.3
				c-0.3,0.1-0.7,0.3-1,0.4c-0.6-1-1.1-1.9-1.5-2.6c-1.3,3.7-2.5,7.4-3.9,11.5c-0.8-1.1-1.4-1.9-2.3-3.3c-1.7,2.4-3.2,4.7-5,7.3
				c-0.6,0-1.9-0.1-3.8-0.1c0.4,3,0.8,5.6,1.3,8.8c-3.4-1-3.6-3.5-4.2-5.4c-1.1-3.7-1.5-7.6-2.7-11.3c-2.9-9.1-6-18.2-9.3-27.2
				c-2.8-7.6-5.7-15.2-8.8-22.6c-11.1-26.6-22.5-53.1-33.5-79.8c-3.8-9.2-6.7-18.9-10-28.3c-2.7-7.6-5.1-15.2-8.3-22.7
				c-2.3-5.4-3.1-11.5-4.8-17.3c-1.7-5.8-2.5-12-6.7-17.6c0.7,3.5,1.3,6.3,1.7,9.2c0.6,4.3,0.9,8.7,1.4,13c0.1,0.7,0.1,1.4,0.2,2.1
				c1.5,6,2.5,12.1,4.5,17.9c3.8,10.8,6.5,21.8,10.2,32.6c1.2,3.3,2.9,6.8,2.8,10.1c-0.2,10.5,4.2,19.3,8.3,28.4
				c2.4,5.2,4,10.8,6.2,16.1c5.4,13.4,10.8,26.7,16.3,40.1c2.8,6.9,5.8,13.7,8.8,21c-2.9,0.5-3-1.3-3.5-2.4c-2.8-6.4-5.6-12.9-8.3-19.4
				c-7.1-16.7-13.7-33.6-21.3-50c-6.9-14.8-11.1-30.4-16.9-45.5c-0.8-2.1-1.5-4.3-2.5-6.4c-0.7-1.4-1.9-2.7-3.2-3.8
				c4.3,17,9.3,33.6,16.2,49.6c8.6,20,17,40.2,25.1,60.5c4.1,10.4,7.4,21.2,11.1,31.8c0.7,2,1.2,3.9,0.2,6.1c-0.7,1.6-0.3,3.8-0.2,5.7
				c0.2,2.8,0.5,5.5,0.8,8.7c-3.1-1.4-3.2-3.7-3.8-5.9c-3.1-10.5-6.4-20.9-9.6-31.3c-0.3-0.9-1-1.6-1.9-2.1
				c3.5,11.7,6.9,23.4,10.4,35.1c-1.6,0.6-2.6,1-3.9,1.5c-0.8-0.8-1.8-1.8-2.7-2.8c-0.1,0.2-0.4,0.5-0.4,0.7c0.3,1.9,0.5,3.8,1,5.7
				c0.8,3,0.3,5.5-3.1,7.3c-0.5-2.7-0.9-5.3-1.6-7.7c-1.5-5.2-3.3-10.4-4.8-15.6c-6-20.5-15.8-39.4-23.7-59.1
				c-3.6-9.1-7.4-18.2-11.2-27.3c-4-9.6-8.1-19.1-12.1-28.7c-1.1-2.5-2-5.2-3.7-7.5c2.1,6.3,3.9,12.7,6.3,18.8
				c3.6,9.2,7.7,18.1,11.5,27.2c6.3,14.9,12.4,29.9,19,44.7c5.4,12.2,10.4,24.6,14.1,37.5c1.8,6.4,3,12.9,2.6,19.6
				c-0.1,0.9-0.8,1.7-1.9,3.9c-1.4-4.3-2.7-7.2-3.3-10.4c-2.2-12-7.4-23-11.7-34.3c-5.8-15-12.4-29.8-18.9-44.5
				c-6.2-14.2-12.7-28.2-19.1-42.3c-0.6-1.4-1.6-2.7-2.4-4c-0.9-1.6-2.1-3.2-2.7-4.9c-3-9.2-5.9-18.4-8.9-27.6
				c-1.4-4.2-3.1-8.2-5.2-12.1c1,3.7,2,7.4,3,11c4.6,15.9,8.6,31.9,15,47.2c8.2,19.4,15.9,39.1,24.7,58.2c6.6,14.4,12.2,29.2,17.5,44.1
				c2.6,7.3,4.7,14.8,2.1,23.8c-2.8-7.3-5.2-13.7-7.8-20.5c0.1,2.8,0.2,5.2,0.2,7.3c-1.6-4.3-3.4-8.8-5-13.4
				c-1.9-5.4-3.7-10.9-5.5-16.4c-0.4-1.2-0.7-2.4-1.3-3.5c-11.1-21.9-20.1-44.8-29.5-67.5c-2.3-5.5-5.3-10.7-7.6-16.1
				c-2.2-5.2-4.1-10.5-6.1-15.7c-1.2-4-2.4-8-3.6-12.1c-0.4,0.2-0.9,0.3-1.3,0.5c1,2.7,2,5.4,3.1,8.1c0.5,1.2,1.3,2.4,1.9,3.6
				c0,1.5-0.5,3.1,0,4.4c3,9.3,5.8,18.8,9.4,27.9c8.1,20.2,16.8,40.2,25.1,60.4c4.3,10.4,8.2,21,12.3,31.5c0.1,0.3-0.1,0.7-0.2,1.8
				c-6.5-15.5-12.3-30.6-19-45.2c-6.7-14.7-11.5-30.1-18.8-44.5c-0.4,0.2-0.8,0.3-1.2,0.5c2,5.2,3.7,10.4,5.9,15.5
				c5.9,13.7,12.2,27.3,17.8,41.1c3.7,9.1,6.6,18.5,9.7,27.9c1.5,4.5,2.8,9,4.1,13.6c1.4,4.9,1.5,9.7-1.3,14.7
				c-1.1-2.9-2.1-5.5-3.3-8.7c-0.6,1.4-0.9,2.3-1.3,3.4c-5.3-6.4-7-14.3-9.7-21.8c-2.2-6.1-4.7-12-7.1-18c-0.4,0.1-0.8,0.3-1.1,0.4
				c1.2,3.3,2.5,6.5,3.5,9.8c3,9.6,6,19.1,8.8,28.7c0.5,1.7,0.7,3.5,0.6,5.2c0,0.6-1.2,1.2-2.5,2.4c-1.8-6.1-3.5-11.3-4.8-16.7
				c-3.3-13.6-8.9-26.3-14.3-39.1c-6-14.3-12-28.5-18.2-42.7c-2.1-4.8-4.9-9.2-7.3-13.8c-2.2-4.2-4.3-8.4-6.8-12.6
				c1.2,4.2,2.1,8.5,3.7,12.5c7,17.1,14.2,34.1,21.3,51.2c4.9,11.7,10,23.3,14.3,35.2c3,8,4.9,16.5,7.2,24.8c0.4,1.5,0.4,3.1,0.5,4.5
				c-1.3,0.2-2.5,0.4-4.3,0.7c-1.7-5.5-3.5-11.7-5.4-17.8c-0.3,0.1-0.6,0.2-0.9,0.3c0.7,2,1.4,4.1,1.9,6.1c1.1,4.4,1.6,6-1,9.7
				c-1.8,2.5-1.9,5.2-2.8,8.1c-1-1-2-1.5-2.3-2.4c-5.3-14.3-10.4-28.6-15.7-42.9c-2.3-6.2-4.2-12.8-7.9-18.2
				c-8.3-12-12.3-25.8-18.2-38.8c-5.9-13-11.6-26-16.7-39.4c-4.6-11.9-8.1-24.2-12.2-36.3c-3.5-10.7-7.1-21.4-10.6-32.1
				c-0.4-1.2-1.1-2.4-2.2-3.4c0.8,3.2,1.4,6.4,2.4,9.6c3.1,10.3,6.6,20.4,9.2,30.8c1.7,6.8,3.4,13.5,5.4,20.2
				c8.1,26.5,20.3,51.3,30.8,76.8c2.3,5.7,5.4,11,7.9,16.7c2.6,6,4.6,12.2,7.3,18.1c4.5,9.8,7.2,20.2,10.3,30.4
				c0.8,2.5,1.8,4.9,2.1,7.6c-0.6-1.6-1.2-3.2-1.8-4.7c-3.4-1.3-4.4,0.1-4.6,3.1c-1.2-3.8-2.2-7.6-3.7-11.3
				c-4.9-11.6-10.1-23.1-14.9-34.8c-4.7-11.6-8.7-23.4-13.4-34.9c-2.3-5.8-5.4-11.3-8-17c-1.8-3.9-3.4-8-5.3-11.9
				c-0.7-1.5-1.9-2.9-3-4.3c-2-2.6-4-5.1-6.8-8.6c3.9,9.5,7.4,17.9,10.9,26.3c2.5,6,5.6,11.8,7.5,18c1.8,5.9,5.5,10.7,7.3,16.5
				c1.1,3.7,2.4,7.3,3.6,10.9c0.3,1,0.7,2,1.1,2.9c6.2,14.4,12.3,28.8,16.6,43.9c0.1,0.5,0,1-0.1,2.8c-2.2-3.8-3.8-6.6-5.9-10.2
				c0.7,4.1,1.2,7.2,1.7,10.2c-3.2,1-1.6,5.1-4.3,7.2c-4.5-9.6-8.1-18.9-14.2-26.8c-0.3,0.2-0.6,0.3-0.9,0.5c0.9,3.2,1.5,6.5,2.7,9.5
				c1.7,4.2,2.9,8.3,1.1,13.6c-2.2-5.9-4.2-11.2-6.3-16.6c-1,0.3-1.5,0.4-1.9,0.5c-6.2,2.2-7.6,1.7-10.3-4.5
				c-7.9-17.8-15.6-35.8-23.9-53.5c-5.2-11.1-11.3-21.8-17.1-32.7c-1.6-2.9-3.8-5.4-5.6-8.2c-1.5-2.4-2.9-4.8-4-7.4
				c-2-4.5-4-9-5.5-13.7c-3-8.9-7.3-17.2-12.1-25.1c-4.6-7.7-6.9-16.1-9.7-24.4c-4.3-12.8-8.7-25.6-12.9-38.5
				c-4.7-14.4-9.5-28.7-13.9-43.2c-1-3.4-0.4-7-1.6-10.6c-6.8-19-13-38.3-20.1-57.2c-3.6-9.5-7-19-9.8-28.8c-1.3-4.6-4.2-8.8-6.1-13.3
				c-2.1-4.6-5.1-9.2-5.6-14.1c-1.3-11.8-6.9-22.1-10.4-33.1c-0.5-1.7-1.9-3.5-0.2-5.7c0.9,1,1.6,1.8,3,3.3c0-1.7,0.1-2.6-0.1-3.5
				c-1.4-6.1-2.3-6.8-7.5-6.2c-3.8-10.1-7.7-20.4-11.7-30.7c-3.7-9.8-7.6-19.6-11.3-29.4c-1.1-2.9-2.7-5.6-2.4-9
				c0.1-1.5-1.3-3.2-2.2-4.7c-1.1-2-2.6-3.8-3.4-5.8c-3.2-8.4-6.1-16.8-9.2-25.2c-0.4-1.1-1.3-2.1-1.8-3.2c-1.4-3.6-2.7-7.2-4-10.8
				c-0.5-1.4-1.1-2.9-1.7-4.3c-0.3-0.6-1-1.1-1.2-1.7c-1.8-7.5-6.8-13.6-9-21c-2.5-8.7-6.9-16.7-10.2-25.1
				c-4.9-12.8-9.9-25.6-13.8-38.7c-1.5-4.9-1.6-10.3-3.5-15.2c-0.9-2.3-1.8-4.6-2.5-7c-0.3-0.9,0.1-1.9,0.2-2.9h-0.1c2,6,4,12,6,18
				c3.1,9.1,6.2,18.3,9.5,27.3c3.9,10.5,8.1,20.9,12.2,31.3c4.8,12,9.6,24,14.5,36c3.4,8.3,7,16.5,10.3,24.8c3.9,9.9,7.5,20,11.5,30
				c1.6,4.1,3.9,8,6.7,11.6c-0.5-1.3-1-2.6-1.5-3.9c-8.7-22.6-17.4-45.3-26.2-67.9c-3.7-9.5-8-18.8-11.7-28.3
				c-5.7-14.4-11.2-28.8-16.8-43.2c-3.5-9-7-18-10.5-27c-1.1-3-1.8-6.1-2.9-9.1c-1.6-4.6-3.4-9.2-4.9-13.9c-1.7-5.4-3-10.8-4.7-16.2
				c-0.8-2.5-2.1-4.8-3.1-7.2c-0.6-1.3-1.5-2.5-1.6-3.8c-0.4-4.8-2.3-9-4.6-13.2c-1.9-3.5-2.6-7.6-4.2-11.3c-0.5-1.3-2-2.1-3-3.2
				L-61.5,294.7z M-62.5,264.4c-3.6-9.5-7.1-19.1-10.9-28.5c-3.8-9.5-7.9-18.8-11.7-28.3c-3.7-9.4-6-19.3-10.8-28.3
				C-86.8,208.5-78.1,237.7-62.5,264.4z M407.2,830.4c-0.5,4.1,9.6,31.8,13.1,36.1C418.3,853.1,410.8,842.5,407.2,830.4z M296.8,830
				c0.5-0.1,1-0.3,1.4-0.4c-4.5-13.4-6.8-27.5-11.3-40.9c-0.5,0.1-0.9,0.2-1.4,0.4C289.3,802.8,293,816.4,296.8,830z M251.7,620.8
				c-0.4,0.1-0.9,0.2-1.3,0.2c4.3,13.4,8.5,26.7,12.8,40.1c0.3-0.1,0.5-0.2,0.8-0.2C259.9,647.5,255.8,634.1,251.7,620.8z M287.4,741.5
				c0.5-0.2,1-0.3,1.5-0.5c-2.5-7.5-4.9-15-7.4-22.5c-0.3,0.1-0.7,0.2-1,0.4C282.7,726.4,284.1,734.2,287.4,741.5z M277.1,708.2
				c0.4-0.1,0.9-0.3,1.3-0.4c-0.6-8.3-5.1-15.4-8-23C272.6,692.6,274.9,700.4,277.1,708.2z M70,641.6c0.8-3-0.5-7.1-4.2-12.1
				C66.3,634.6,67.8,638.1,70,641.6z M-118.1-340.6c-2.2-5.9-4.2-11-6.1-16C-122.4-351.5-123.7-345.1-118.1-340.6z M272.4,831.3
				c1.6,4.1,3.1,8.2,4.7,12.3C277.4,838.8,275.5,834.9,272.4,831.3z M48.4,582.9c0.3-0.1,0.6-0.2,0.9-0.3c-1.6-4.3-3.3-8.6-4.9-12.9
				c-0.1-0.3-0.5-0.4-1.4-0.9C45,573.8,46.7,578.3,48.4,582.9z M196,934.9c-0.3,0.1-0.6,0.3-0.8,0.4c1.2,2.8,2.5,5.6,3.7,8.4
				c0.1-0.1,0.3-0.1,0.4-0.2C198.2,940.6,197.1,937.8,196,934.9z M280.3,851.8c-0.3,0.1-0.6,0.1-0.9,0.2c1.2,3.2,2.4,6.3,3.6,9.5
				c0.1,0,0.2-0.1,0.3-0.1C282.4,858.2,281.4,855,280.3,851.8z M-109.5-265.4c0.2-0.1,0.4-0.2,0.6-0.2c-0.7-1.9-1.5-3.9-2.2-5.8
				c-0.3-0.6-0.6-1.2-1-1.8c-0.1,0.1-0.2,0.1-0.3,0.2C-111.4-270.5-110.4-268-109.5-265.4z M-104.8,150.4c-0.2,0.1-0.4,0.2-0.5,0.3
				c1.1,3,2.3,6,3.4,9c0.2-0.1,0.4-0.1,0.6-0.2C-102.5,156.4-103.7,153.4-104.8,150.4z M256.8,875.5c-0.3,0.1-0.7,0.2-1,0.3
				c0.5,1.6,1,3.1,1.5,4.7c0.5,1.3,1,2.7,1.5,4c0.3-0.1,0.5-0.2,0.8-0.4C258.6,881.3,257.7,878.4,256.8,875.5z M408.8,849
				c-0.1,0.1-0.3,0.1-0.4,0.2c1.1,2.8,2.1,5.7,3.2,8.5c0.3-0.1,0.6-0.2,0.8-0.4C411.3,854.5,410.1,851.8,408.8,849z M210,139.1
				c-1.5,1.8-1,2.9,0.2,3.7c0.2,0.1,0.6,0,1.2-0.1C210.9,141.6,210.5,140.5,210,139.1z M262.6,895.5c0.3-0.1,0.6-0.1,0.9-0.2
				c-0.9-2.3-1.7-4.5-2.6-6.8c-0.2,0.1-0.3,0.1-0.5,0.2C261.2,891,261.9,893.3,262.6,895.5z M70.4,650c0-2.2,0.4-4.6-3.6-5.6
				C68.4,646.9,69.4,648.4,70.4,650z M255.6,855.4c0.3-0.1,0.5-0.2,0.8-0.3c-0.7-1.7-1.5-3.4-2.2-5.1c-0.1,0-0.1,0.1-0.2,0.1
				C254.5,851.8,255.1,853.6,255.6,855.4z M196.2,140.6c-0.4,0.1-0.8,0.3-1.2,0.4c0.5,1,1,2,1.5,3c0.3-0.1,0.6-0.2,0.9-0.4
				C197,142.7,196.6,141.7,196.2,140.6z M301.6,1009.3c-0.5,0.1-0.9,0.3-1.4,0.4c0.3,1.3,0.5,2.5,0.8,3.8c0.4-0.1,0.8-0.1,1.2-0.2
				C301.9,1012,301.8,1010.6,301.6,1009.3z M415.9,918.6c-0.3,0.1-0.5,0.2-0.8,0.3c0.7,1.5,1.4,2.9,2.2,4.4c0.1-0.1,0.2-0.1,0.4-0.2
				C417.1,921.6,416.5,920.1,415.9,918.6z M-105.4,172.3c0.4-0.2,0.8-0.4,1.2-0.5c-0.4-0.7-0.6-1.5-1.1-2.2c-0.1-0.1-0.8,0.3-1.3,0.4
				C-106.2,170.7-105.8,171.5-105.4,172.3z M-108.5,138c-0.2,0.1-0.3,0.1-0.5,0.2c0.5,1.3,1,2.7,1.5,4c0.2-0.1,0.3-0.1,0.5-0.2
				C-107.5,140.7-108,139.3-108.5,138z M-117.6,111.1c-0.1,0-0.1,0-0.2,0.1c0.2,0.6,0.4,1.2,0.7,1.7h0.1
				C-117.3,112.2-117.4,111.7-117.6,111.1z"/>
            </g>
        </g>
        <g clip-path="url(#clipFlower3)"> 
            <g class="a-fill filler">
                <path d="M170.7,81.8c2.8-3.3,3.6-7.4,4.6-11.4c0.2-1,1.2-2.4-0.1-3.5c0.3-0.3,0.5-0.7,0.8-0.9c1.5-0.8,1.8-1.2,1-1.9
				s-0.6-1.2-0.4-1.7c2.5-7.3,4.3-14.8,7.9-22c0.1,0.8,0.1,1.2,0.2,2.2c1.7-3.9,2.9-7.4,4.1-10.9c-0.3-0.1-0.6-0.2-0.8-0.4
				c-0.6,1.6-1.2,3.1-1.9,4.9c-0.1-0.5-0.2-0.8-0.2-1c2.1-6.2,4.2-12.5,6.4-18.7c1.4-3.9,3.2-7.6,4.6-11.4c5.1-13.6,10.1-27.3,15.2-41
				c3.2-8.6,6.8-17,10.1-25.6c1.7-4.5,3.3-9,5-13.4c2.1-5.2,4.4-10.4,6.4-15.6c0.5-1.4,2.4-0.4,2.4-2.4c0-2.1,0.7-4.3,1.5-6.3
				c1.5-4.1,3.3-8.2,5.1-12.2c0.2-0.4,1.1-0.4,2.1-0.8c1.9-4.8,4.5-10,5.9-15.4c0.8-3,2.9-5.1,3.7-7.9c0.5-1.6,0-3.7,1.8-4.9
				c0.1-0.1,0.2-0.6,0.1-0.7c-2.1-1.9-0.1-3.8,0.4-5.6c0.3-1.3,0.7-2.6,1.1-3.9c0.4-1.2,0.8-2.4,1.2-3.6c0.2,0.1,0.5,0.1,0.7,0.2
				c-0.4,2.3-0.8,4.6-1.1,6.9c-0.2,1.9,0,3.9-0.3,5.7c-0.5,2.4-1.2,4.7-2.1,7c-1.4,3.5-3.1,7-4.5,10.5c-1.8,4.4-3.6,8.8-5.4,13.2
				c-2.2,5.3-4.4,10.6-6.6,15.9c-3.4,8.1-6.7,16.3-10.1,24.5c-2.1,5.1-4,10.2-6,15.3c-2,5.1-4.1,10.2-6.2,15.4
				c-0.3,0.8-0.6,1.6-0.9,2.4c-3.3,8.9-6.5,17.8-9.8,26.7c-4.2,11.3-8.5,22.6-12.6,33.9c-2.3,6.4-4.5,12.8-6.7,19.3
				c-1.8,5.4-3.3,11-5.2,16.4c-0.9,2.6-0.6,5.9-3.7,7.6c-0.4,0.2-0.4,2-0.2,3c0.1,0.9-0.1,0.9-0.8,1.3c-0.8,0.6-1.6,2.6-1.2,3.5
				c0.9,2.2-0.4,3.7-1.1,6.1c-0.1-1-0.1-1.4-0.2-1.8c-0.2,0.1-0.4,0.1-0.5,0.2c-0.4,1.2-0.9,2.3-1.1,3.5c-0.3,1.1-1.3,2.2,0,3.3
				c0.2,0.2-0.2,1.3-0.4,2c-1.3,4.3-2.6,8.6-3.9,12.9c-0.5,1.7-0.6,3.6-2.2,4.8c-0.3,0.2-0.5,0.8-0.6,1.3c-0.8,3.4-1.6,6.7-2.5,10.1
				c-0.4,1.4-1,2.7-1.5,4.1c-0.2,0.6-0.7,1.2-0.6,1.7c0.6,2.4-0.8,4.2-2,5.9c0.8,1.8,1.8,1.4,3.2,0.2c-0.7,2.1-1.3,4-1.8,5.8
				c-0.1,0-0.1,0-0.2,0c0.1-1.1,0.3-2.1,0.6-4.1c-1.1,1-1.9,1.4-2.1,2c-1.1,3-2.3,6-2.9,9c-2.5,11.9-6.6,23.3-9.9,35
				c-1.3,4.8-2.4,9.7-3.7,14.5c-0.7,2.5-1.7,4.9-2.4,7.3c-0.3,1.1-0.4,2.2-0.7,3.4c0.2,0.1,0.5,0.1,0.7,0.2c0.4-1,0.8-2.1,1.2-3.1
				c4.7-14.6,9.4-29.2,14.2-43.8c1.4-4.2,3.4-8.1,5-12.2c0.9-2.3,1.5-4.7,2.3-7c1.4-4.5,2.9-9,4.5-14.1c1.7,2.5,0.3,4.4,0,6.3
				c1.7-2.2,2.8-5.5,2.9-8.7c-0.1-0.1-0.2-0.2-0.2-0.2c-0.4,0.2-0.7,0.5-1.3,0.8c-0.1-0.6-0.3-1.1-0.1-1.5c2.6-8.3,5.3-16.7,8.1-25.5
				c0.9,0.8,1.4,1.2,2,1.8c2.6-4.1,3.4-8.9,5-13.3c1-2.9,1.9-5.8,2.8-8.7c1-3.2,2.1-6.5,3.2-9.7c1.3-3.7,2.6-7.4,3.9-11.1
				c0.4-1.3,0.7-2.6,1.1-3.8c2-5.2,3.9-10.4,5.5-15.8c0.3-0.9,1.1-1.7,1.6-2.5c0.2-0.4,0.2-0.9,0.3-1.4c0-0.3-0.1-1-0.1-1
				c2.9-1.1,1.4-3.9,2.3-5.8c1.2-2.6,1.5-5.5,3.5-7.9c1.2-1.3,1.1-3.7,1.7-5.6c3.1-9.2,6.2-18.3,9.4-27.4c1.3-3.8,2.4-7.8,4.5-11.1
				c2.7-4.2,3.6-9.1,5.8-13.4c1.4-2.6,2.7-5.5,3.1-8.8c0.3-2.6,1.5-5.2,2.4-7.7c2.3-6,4.8-12.1,7.2-18.1c0.4-1,1-1.9,2-2.7
				c-0.6,2-1.3,4.1-1.9,6.1c0.2,0.1,0.4,0.1,0.5,0.2c0.3-0.7,0.5-1.5,0.9-2.2c1.1-2.4,2.4-4.8,3.5-7.2c0.8-1.8,1.6-3.5,2.3-5.3
				c0.6-1.7,0.9-3.6,1.6-5.2c2.8-6.4,5.7-12.7,8.5-19.1c0.4-0.9,0.3-1.9,0.7-2.8c0.2-0.5,1.2-1,1.6-0.8c0.4,0.1,0.8,1.1,0.8,1.6
				c-0.1,0.8-0.5,1.6-0.9,2.4c-1.6,3.7-3.3,7.3-4.9,11c-1.9,4.7-3.6,9.5-5.6,14.3c-1.8,4.2-3.8,8.3-5.6,12.5c-0.5,1.2-0.7,2.5-1.2,3.7
				c-2,4.9-4.2,9.8-6.2,14.8c-1.3,3.3-2.2,6.7-3.4,10.1c-0.6,1.7-1,3.6-1.8,5.2c-2.3,4.6-3.9,9.4-5.6,14.2c-3,8.5-6.5,16.7-9.7,25.1
				c-2.3,6.2-4.3,12.4-6.6,18.6c-2,5.3-4.1,10.5-6.2,15.8c-0.4,1-0.8,2-1.2,3.1c-3.3,10.1-6.1,20.4-9.9,30.3
				c-2.3,6.1-3.7,12.4-5.9,18.5c-1.4,3.9-3,7.6-4.4,11.5c-0.2,0.4-0.3,0.9-0.4,1.4c-1.5,8.8-4.7,17.2-7.1,25.8
				c-0.2,0.8-0.3,1.5-0.5,2.3c0.2,0.1,0.4,0.2,0.7,0.3c0.4-1,0.9-2,1.3-3.1c4.3-13.3,8.4-26.7,12.8-40c4.4-13.2,9.1-26.2,13.6-39.3
				c1.9-5.5,3.8-11.1,5.8-16.6c5.4-15.1,10.8-30.2,16.5-45.3c5.9-15.6,12-31.1,18-46.6c3.3-8.3,6.8-16.5,10.1-24.8
				c3.1-7.8,6-15.8,9.1-23.6c1.1-2.7,2.5-5.2,3.7-7.9c2.1-4.8,4.2-9.6,6.3-14.5c4.3-10.1,8.5-20.2,11.9-30.7c0.1-0.2,0.3-0.3,0.9-0.9
				c-2.3,7.2-4.4,13.8-6.5,20.4c0.7,0.4,1.6,0.7,1.6,1c0,0.9-0.4,1.9-0.7,2.8c-2.6,7.7-5.2,15.3-7.8,23c-0.3,0.9-0.6,1.8-0.5,2.7
				c3-4.9,4.2-10.5,6.8-15.7c-0.1,1,0,2-0.3,3c-4.1,12.1-7.7,24.3-12.9,36c-2.9,6.5-5.5,13.1-8.2,19.7c-0.2,0.5-0.4,1.1-0.3,1.8
				c4.9-7.7,7.3-16.8,12.3-24.4c-0.9,3.7-3.9,6.8-3.2,11.6c0.7-1.8,1.1-3,1.6-4.2s1.3-2.4,1.8-3.6c2.2-5.3,4.3-10.6,6.5-15.9
				c1.3-3.3,2.8-6.5,4-9.8c2.8-7.8,5.4-15.8,8.1-23.6c0.7-2.1,1.9-4,2.5-6.1c0.8-2.8,1.3-5.7,2-8.5c0.3-1.2,0.7-2.3,1.7-3.4
				c-0.2,2-0.1,4-0.6,5.9c-1.8,7-3.7,13.9-5.8,20.8c-1.9,6.2-4.1,12.3-6.6,18.3c-1.2,2.9-2.9,5.6-2.9,8.7c1.5-2.6,3-5.3,4.4-7.8
				c1.2,0.1,2.1,0.2,3.3,0.3c-0.2,0.9-0.5,2-0.7,3.2c0.1,0.1,0.2,0.1,0.3,0.2c0.5-1.2,0.9-2.4,1.4-3.6c0.6-1.3,1.5-2.5,2.1-3.8
				c0.9-1.8,0.9-4.3,3.3-5.1c0.4-0.1,0.5-1.1,0.7-1.6c1.9-5.8,3.8-11.6,5.8-17.4c1.8-5.2,3.6-10.4,5.4-15.6c0.1-0.3,0.4-0.7,0.4-1
				c-0.9-4.7,1.9-8.7,2.8-13c0.6-2.6,1.6-5.1,2.4-7.6c-0.8,4.1-1.4,8.4-2.3,12.6c-1,4.4-2.4,8.7-3.3,13.2c3.2-2.2,3-6.4,5.4-9.9
				c0.2,4-2,6.9-1.7,10.4c1.1-0.7,1.9-1.2,3.2-2c-0.3,2-0.4,3.4-0.6,4.9c0.2,0.1,0.3,0.2,0.5,0.4c0.6-0.6,1.4-1.1,1.8-1.8
				c0.7-1.3,1.1-2.7,1.7-4.1c1.5-3.5,3-6.9,4.4-10.4c1.3-3.2,2.4-6.4,3.7-9.9c1.3,1.9,0.8,3.6,0.3,5.3c-3.7,11.9-7.5,23.9-11.2,35.8
				c-0.3,0.9-0.5,1.9-1,3.6c2.9-2.7,2.7-6,4-8.1c0.2,9.4-3.3,18.4-6.2,27.5c-0.5,1.5-0.9,3-1.4,4.5c0.2,0.1,0.4,0.2,0.6,0.2
				c1.2-2.8,2.4-5.7,3.7-8.5c1.1-2.4,1.6-5,3.4-7.2c1.2-1.4,1.4-3.6,2-5.5c3-9.1,5.9-18.2,8.8-27.3c0.1-0.4,0.1-0.8,0.3-1
				c2.5-2.7,3-6.2,3.9-9.5c3.1-10.5,6-21,9.1-31.5c2.1-7.1,4.3-14.3,6.5-21.4c0.4-1.3,1.4-2.5,2.5-3.6c-2.7,15.9-7.9,31.2-10.8,47.1
				c2-2.1,4.6-3.6,4.7-7.1c4.4,1.3,3.6-2.9,4.5-4.3c0,1.1,0.1,2.8,0.1,4.4c0,0.6-0.1,1.2-0.2,1.8c-1.7,6.9-2.6,13.9-4.9,20.6
				c-0.3,0.8-0.3,1.8-0.5,2.7c0.3,0.1,0.5,0.2,0.8,0.3c0.9-2,1.7-3.9,2.6-5.9c-0.9,9.2-3,18.1-5.8,27.1c2.9-2.3,4.1-4.4,5.1-7.5
				c1.3-4.1,2.8-8.1,4.3-12.1c1.4-3.7,2.7-7.3,4.2-11c0.5-1.3,1.3-2.5,1.9-3.6c1.5,1,3.5,1.6,3.7,2.6c0.3,2.1,1.9,1.7,3.1,2.6
				c-0.1,0.7-0.1,1.6-0.2,3c0.6-0.8,1.1-1.1,1.1-1.5c0.2-1.9,0.6-3.4,2.5-4.5c0.9-0.6,1.4-2.2,1.8-3.4c1.4-4.3,2.8-8.6,4.4-12.9
				c0.2,3.7-1.8,7.6,0.5,11.3c1.5-0.3,0.9-2.2,1.6-3.3c0.7-1.1,1.5-2.2,2.2-3.2c1,2,0.2,4.2-0.3,6.4c-1.9,7.6-3.7,15.2-5.6,23
				c0.9,0.3,1.6,0.5,2.6,0.8c-0.1,0.8-0.2,1.6-0.2,2.4c0,1.4,0,2.8,0,4.3c0,0.7,0,1.4-0.2,2.1c-1.4,6.2-2.9,12.4-4.3,18.6
				c-1.1,5.1-2,10.3-3.2,15.4c-0.7,3.3-1.8,6.6-0.9,10.3c0.6-0.7,1.1-1.2,1.3-1.7c2.9-9.4,5.6-18.8,8.6-28.2c2.7-8.5,5.4-17,8.3-25.4
				c0.8-2.3,2.2-4.3,3.2-6.5c0.8-1.6,1.4-3.3,2.4-5.4c0,3.7,0.1,6.9,0.1,10.1c3.2,0.3,3.6,0.1,4.2-2.3c0.4-1.5,0.6-3.1,1.1-4.7
				c0.7-2.3,1.9-4.3,4.4-5.2c0.6,0.8,1.1,1.5,1.6,2.4c2.6-2.1,3.9-4.8,4.8-7.7c1-3.6,1.8-7.3,2.7-11c0.5-2.2,0.5-2.2,3.2-3.6
				c-0.9,3.9-1.7,7.6-2.7,11.2c-0.4,1.4-0.4,2.5,0.5,3.7c1.4,1.8,1.3,3.7,0.4,6c-1.3,3.1-1.8,6.5-2.3,9.9c2.6-3.5,2.2-8.4,5.7-11.4
				c1.5,0.3,1.9,1.3,1.8,3.2c-0.4,6-2.4,11.7-3.8,17.5c-2.2,9.5-4.9,19-7.4,28.5c-0.1,0.2,0,0.5,0,1c0.7,0.1,1.5,0.1,2,0.2
				c1.9-4.5,3.9-8.8,5.5-13.2c1.6-4.3,2.9-8.8,4.2-13.2c2-6.4,4-12.9,6-19.3c0.3-1,0.9-1.9,1.3-2.9c0.2,0.1,0.5,0.1,0.7,0.2
				c-0.3,1.7-0.5,3.5-0.9,5.1c-2,7.8-4,15.7-6,23.5c-1.7,6.8-3.6,13.5-4.9,20.3c-0.9,4.8-2,9.5-3,14.3c-0.6,2.7-0.8,5.5-1.2,8.2
				c-1.1,8.1-3.7,15.7-6,23.5c-1.3,4.4-2.5,8.9-3.7,13.4c-1.2,4.4-2.2,8.8-3.6,13.1c-1.6,5.1-3.5,10.1-5.3,15.1
				c-1.5,4.3-3,8.6-4.5,12.9c-0.1,0.2,0,0.5-0.2,0.7c-3.4,5.6-4.7,11.9-7.1,17.9c-2.4,5.9-4.8,11.8-7.1,17.8
				c-2.1,5.7-4.1,11.4-6.1,17.1c-5.6,15.9-11.1,31.8-16.6,47.7c-4.2,11.8-8.6,23.6-12.6,35.5c-4.8,14.7-9.3,29.4-13.8,44.2
				c-2.7,8.9-5,17.9-7.6,26.9c-2.8,9.5-5.8,19-8.7,28.5c-2.7,8.9-5.3,17.8-8.1,26.6c-3.2,10-6.6,20-9.8,30c-2.9,9.4-5.6,18.8-8.5,28.2
				c-3.3,10.9-6.5,21.8-10,32.6c-3.8,11.9-7.9,23.7-12,35.5c-2.4,6.8-5,13.5-7.4,20.4c-3.1,8.9-5.9,17.9-8.9,26.8
				c-3.9,11.5-8,22.9-11.8,34.4c-2.3,6.7-4.2,13.6-6.4,20.4c-3.5,11-7,21.9-10.6,32.9c-2.9,8.9-5.9,17.7-8.7,26.6
				c-2.6,8.2-4.9,16.5-7.5,24.6c-2.5,7.7-5.3,15.3-7.9,22.9c-1.5,4.5-2.8,9.2-4.1,13.8c-1.9,6.7-3.8,13.4-5.7,20.2
				c-1.5,5.6-3.1,11.1-4.6,16.7c-2.1,7.9-4.1,15.9-6.3,23.8c-3.3,11.9-6.5,23.9-10.2,35.7c-3.1,10.3-6.7,20.5-10.2,30.7
				c-2.6,7.6-5.6,14.9-9.9,21.7c-1.7,2.8-3.8,5.3-7.1,6.1c-1.1,0.3-2,1.5-3.2,2.5c-0.9-2.3-1.8-4.4-2.8-6.8c-1.7,1.1-3.4,2.2-5.4,3.5
				c-0.9-1.9-1.8-3.7-2.7-5.6c-1.9,0.8-3.8,1.5-5.9,2.3c-1.7-2.6-3.7-1.2-5.7,0.2c-0.9-1.6-1.6-3-2.2-4.1c-1-1-2.1,1.4-2.8-0.6
				c-0.1-0.4-1.8-0.1-2.9-0.1c-0.2-0.3-0.5-0.7-0.7-1c-2.3-3.3-2.3-6.4,0.2-9.9c1.2-1.7,1.7-4,2.5-6.1c0.1-0.4-0.2-0.9-0.4-2
				c-4.1,5.5-6.4,11.4-9.5,17.2c-0.9-2.2-2.8-3.8-1.9-6.6c0.3-1-0.4-2.3-0.7-4c-1.5,1.7-2.8,3.1-4.1,4.6c-1.3-1.2-2.4-2.9-4-0.1
				c-0.3,0.5-2.3,0-3.8,0c-0.3-1.3-0.7-2.6-1.1-4.4c-1.2,0.5-2.2,0.9-3.5,1.4c-0.6-0.8-1.3-2.3-3-1.4c-1.1,0.6-2.2,1.3-3.5,2.1
				c-0.8-0.3-1.8-0.6-2.8-0.9c-1-0.2-2-0.2-3.1-0.4c-1,0.8-2,1.8-3.1,2.7c-0.2-0.1-0.4-0.2-0.6-0.3c0.7-3.2,0.4-6.2-1.5-9.2
				c-1.5,2.5-2.8,4.8-4.1,7.1c-1.1-0.8-1.9-1.4-2.6-2.1c-1.7-1.7-2.8-3.6-1.9-6.4c0.8-2.4,1.2-5,1.9-7.8c-1.4,0.2-2.5,0.3-3.5,0.7
				c-0.9,0.4-1.7,1-2.4,1.8c-1.1,1.2-2,2.6-3.3,3.8c0-1.7,0.7-3.3-1.1-4.3c-0.3-0.2-0.2-1.6,0-2.4c0.2-1.4,0.6-2.7,0.9-4.1
				c-0.2-0.2-0.5-0.3-0.7-0.5c-1.1,0.8-2.2,1.6-3.5,2.6c-0.1-0.3-0.1-0.8-0.3-1.8c-2.4,3.4-4.5,6.4-6.9,9.8c-1-0.2-2.3-0.2-3-0.8
				c-1.5-1.4-3.1-1.9-5-1.9c-1.7,0-3.3,0-5.2,0c-3.2-3.3-3.2-3.4-1.3-8.2c1.1-2.6,2.2-5.1,3.3-7.7c0.2-0.6,0.2-1.3,0.3-1.9
				c-0.2-0.1-0.4-0.2-0.7-0.3c-0.4,0.8-0.9,1.5-1.2,2.3c-1.6,3.7-3.4,7.4-4.8,11.1c-0.9,2.3-2.3,3.2-4.8,2.8c0-1,0-2,0-3.1
				c-4.3-3-4.3-3-8.3,0.5c-0.3-1.2-0.6-2.2-0.7-3.2s0.1-2.1-0.3-3.3c-0.8,1.2-1.7,2.5-2.7,4c-1.2-1-2.3-2-3.5-3.1
				c-2.1,1.3-4.1,1.4-6.2-0.3c-1.5-1.3-3.6-1.8-5.5-2.6c-0.7-0.3-1.5-0.3-2.2-0.1c-2.4,0.4-2.8,0.2-3-2.3c-0.1-1.3-0.3-2.4-1.3-3.5
				c-0.6-0.7-0.2-2.7,0.1-4.1c0.2-1.2,0.7-2.3,1-3.5c0-0.2-0.2-0.4-0.4-0.8c-1.4,0.4-1.8,1.6-2.3,2.8c-1.2,2.9-2.6,5.7-3.9,8.8
				c-2.3-1.1-4.2-2-6.4-3.1c0.9-3.3,1.9-6.4,2.5-9.7c2-12.4,6.9-24,9.9-36.1c4.5-18.2,9.4-36.4,14.3-54.5c1.7-6.3,3.6-12.5,5.4-18.8
				c0.7-2.4,1.1-4.8,1.8-7.1c0.6-2,0.6-4.2,3.1-5.4c1.1-0.5,1.9-2.8,2.3-4.4c1.4-5.1,2.9-10.2,3.8-15.4c2.9-17.9,8.2-35.3,13-52.8
				c1.7-6.3,3.5-12.5,5.3-18.8c0.5-1.7,1.2-3.4,2.5-5c0,0.6,0.2,1.2,0.1,1.7c-0.4,2.5-1.1,5-1.4,7.6c-0.1,1.5,0.3,3,0.6,5
				c-1.2,0.5-2.8,1.4-2.2,3.8c0.1,0.4-0.2,0.9-0.3,1.3c-1.5,5-3.1,10-4.6,15.1c-0.1,0.3,0.1,0.6,0.2,1.3c1.3-1.3,2.6-2.3,3.1-4
				c2.6-8.8,5.2-17.6,7.8-26.4c2.2-7.3,4.2-14.6,6.5-21.9c5.1-16.2,10.3-32.3,15.4-48.4c6.2-19.4,12.4-38.8,18.4-58.2
				c1.9-6.2,3.9-12.3,6.4-18.2c0.5-1.2,0.3-2.6,0.7-3.9c1.6-5.2,3.1-10.4,4.9-15.4c2.1-5.8,4.6-11.5,7.2-17.2c-1.1,6.9-3.3,13.5-5.7,20
				c-1.8,5-3.8,9.9-5.3,14.9c-1.1,3.6-1.7,7.3-2.5,11c0.2,0.1,0.4,0.2,0.6,0.3c0.3-0.5,0.8-1,1-1.5c2.9-8.1,5.8-16.2,8.6-24.3
				c0.7-2.1,0.8-4.3,1.5-6.4c1.4-4.4,3-8.8,4.5-13.2c0.7-2.2,1.2-4.5,1.9-6.7c2.4-7.2,4.9-14.4,7.4-21.5c0.8-2.3,2-4.5,3.2-7
				c0.4,0,1,0.1,1.8,0.2c1.5-4.6,2.9-9.2,4.4-13.9c-3.1,1.9-1.9,5.7-3.7,8.1c-0.1,0-0.3,0-0.4-0.1s-0.2-0.2-0.2-0.3
				c3-9.6,6.1-19.1,9-28.4c0.5-0.1,1.1-0.1,1.5-0.3c0.2-0.1,0.4-0.8,0.3-1c-1.9-3.6,0.6-6.7,1.4-10c2-8.9,5.2-17.4,7.9-26
				c0.2-0.8,0.6-1.5,1.6-2.1c-0.3,1.4-0.6,2.8-0.8,4.2c0.2,0.1,0.4,0.2,0.6,0.3c0.7-1.3,1.7-2.6,2.1-4c2-6.2,3.6-12.5,5.9-18.5
				c1-2.6,2.9-5,4.4-7.6c-1.6,5.1-3.2,10.1-4.8,15.2c-4.6,14.9-9.2,29.8-13.8,44.7c-4.5,14.5-9,29-13.4,43.6
				c-2.2,7.1-4.2,14.3-6.4,21.4c-2.4,7.5-5,14.9-7.5,22.3c-6,18.1-12.1,36.3-18,54.5c-3.2,9.8-6,19.7-8.8,29.6
				c-2,7.1-3.8,14.2-5.8,21.2c-1.1,3.8-2.5,7.4-3.7,11.2c-0.2,0.7-0.1,1.4-0.2,2.2c0.3,0.1,0.6,0.1,0.9,0.2c1.8-5.3,3.7-10.7,5.5-16
				c0.2,0,0.4,0.1,0.6,0.1c-0.1,0.6,0,1.3-0.3,1.8c-0.9,1.3-0.9,2.7-1.2,4.1c-0.6,3-1.7,6.1-2.9,8.9c-1.1,2.6-2,5.1-2.5,7.9
				c-0.4,1.9-1.2,3.7-1.7,5.6c-0.5,2-0.8,3.9-1.3,6.2c1.9-0.5,1.9-2,2.3-3c0.9-2.7,1.7-5.4,2.6-8.1c0.4-1.3,0.1-3.3,2.4-3.1
				c0.2,0,0.6-1,0.8-1.5c2.5-7.7,4.9-15.4,7.3-23.1c0.3-0.8,0.3-1.7,0.4-2.4c-0.9-0.3-1.7-0.5-2.5-0.8c-0.7,1.5-1.4,3-2,4.5
				c-0.2-0.1-0.4-0.1-0.5-0.2c0.8-3.7,1.5-7.5,2.6-11.1s2.5-7.2,3.9-10.7c1.4-3.4,1.3-7.4,3.8-10.9c1.2,1.8-1.3,4.5,1.7,4.6
				c0.4,2.2-3.4,3.5-1,6.1c0.3-0.2,0.8-0.5,1-1c1-3.1,2.1-6.3,3-9.5c1-3.9,0.9-5.3-0.3-9.4c-0.2-0.6-1-0.9-1.6-1.4
				c1.4-5.3,2.2-10.7,5-15.6c0.9-1.6,1-3.7,1.6-5.6c0.4-1.1,0.3-2.5,1.8-2.9c0.3-0.1,0.5-1.5,0.3-2.2c-0.3-1.5,0.2-2.5,1.6-2.8
				c0-1.2,0-2.4,0-3.5c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0.4,0.8,0.9,0.8,1.3c-0.2,2.1-0.1,4.2-1,6.2c-0.5,1.1-0.5,2.5-0.7,3.8
				c1.7,0.6,2.5,0.1,3-1.4c1.1-3.5,2.4-6.9,3.6-10.4c1.6-4.6,3.2-9.3,4.6-13.9c0.6-2.2,0.7-4.5,1-6.7c-0.2-0.1-0.4-0.1-0.7-0.2
				c-1.7,5-3.5,9.9-5.2,14.9c-0.2,0-0.4-0.1-0.6-0.1c0-0.6,0.3-1.5,0.1-1.7c-2.4-1.8-0.7-3.7,0-5.6c1.4-4.4,2.6-8.9,4-13.3
				c1.4-4.3,2.8-8.6,4.3-12.9c0.4-1.1,1-2.1,1.8-3.7c1.2,2.5-0.2,4.2-0.5,6c-0.3,1.9-0.5,3.8-1,5.7c-0.4,1.8-1.1,3.6-1.8,5.6
				c0.8-0.2,1.1-0.2,1.1-0.3c1.2-3.7,3.4-7,5.1-10.4c2.2-4.3,4-9,5.1-13.7c1.6-7.2,4.4-14,6.5-21c4.1-14,8.6-27.9,12.5-41.9
				c4-14.2,8.6-28.1,13.3-42c5.1-15.4,10-30.8,14.9-46.2c1.4-4.4,2.8-8.9,4.4-13.7c-0.6,0.3-0.9,0.5-1.5,0.7c0.1-1.5-0.1-2.8,1.7-3.4
				c0.5-0.2,1-1.2,1.2-1.8c2.4-7.6,4.8-15.2,7.2-22.8c2.5-8.1,5-16.1,7.7-24.1c3.8-11.6,7.7-23.3,11.6-34.9c1.5-4.5,3.2-9,4.8-13.5l0,0
				c0.3,0.4,1,0.8,1,1.1c-0.1,1.2-0.3,2.4-0.7,3.6c-4.1,11.7-8.4,23.3-12.3,35c-2.7,7.9-5,15.9-7.4,23.8c-2.3,7.3-4.6,14.5-6.8,21.8
				c-6,19.5-12,39.1-18.1,58.6c-2.2,6.9-4.9,13.6-7,20.5c-3.3,10.6-6.4,21.4-9.6,32c-2.8,9.1-5.8,18.2-8.7,27.3
				c-3.4,10.7-6.7,21.5-10.1,32.2c-1.1,3.5-3.2,6.7-3.7,10.2c-0.5,3.3-1.8,6.2-2.6,9.4c-1.3,5.1-2.7,10.2-4.4,15.1
				c-2.3,6.7-5,13.2-7.4,19.9c-1,2.7-1.2,5.7-2,8.5c-1.3,4.4-2.8,8.8-4.3,13.2c-0.4,1.1-1.2,2.2,0.3,3.6c0.9-2.1,2-4,2.7-6
				c2.1-6,3.9-12.1,6.8-17.9c1.3-2.6,1.7-5.6,2.6-8.4c1.4-4.3,2.9-8.6,4.3-12.9c1.6-4.8,3.3-9.5,4.8-14.3c3.6-11.2,6.9-22.5,10.6-33.7
				c1.6-5.1,3.9-10,5.5-15.1c2.3-7.4,4.3-14.8,6.4-22.3c0.9-3.3,1.4-6.7,2.4-9.9c0.8-2.5,2.3-4.8,3.4-7.3c0.7-1.5,1.5-3.1,2-4.7
				c4.1-12.7,8.2-25.5,12.3-38.2c4.8-14.9,9.6-29.9,14.4-44.8c0.3-1.1,0.3-2.5,1.8-2.9c0.3-0.1,0.7-1.2,0.5-1.5c-1-2,0.5-3.5,0.9-5.2
				c-0.2,1.1-0.5,2.2-0.7,3.3c0.2,0.1,0.3,0.1,0.5,0.2c0.3-0.7,0.7-1.4,1-2.1c0.7-2.1,1.3-4.2,1.9-6.3c0.3-1.2,1.4-2.4-0.1-3.5
				c1.8-1.6,0.1-4.3,2.1-6.2c-0.2,1.1-0.3,1.8-0.4,2.6c0.9,0.3,3.4-8.3,3.2-11v0.1L170.7,81.8L170.7,81.8z M238.7-25
				c1.4-3.7,2.9-7.3,4.3-11C240.4-32.7,239.4-28.9,238.7-25c-0.3,0.2-0.8,0.3-0.9,0.5c-1.2,3.6-2.3,7.3-3.5,10.9h0.1
				C235.8-17.4,237.2-21.2,238.7-25z M280.7-102.5c-1.4,4.6-2.8,9.3-4.2,13.9c1.9-3.2,3.3-6.6,4.6-10
				C281.7-99.9,282.1-101.4,280.7-102.5c1.2-1.8,1.7-3.8,1.8-5.9C281.2-106.6,280.4-104.7,280.7-102.5z M248.8-43.9
				c1.8-2.5,2.7-5.4,2.8-8.6C250.7-49.6,249.7-46.7,248.8-43.9c-0.5,0.3-1.2,0.3-1.5,0.7c-1.8,2.8-3.7,5.6-2.5,9.8
				C246.6-37.1,248-40.4,248.8-43.9z M214.5,19.8c0.9-2.5,1.8-5,2.6-7.6c0.3-0.8,0.6-1.6,0.8-2.4c0.3-0.9,0.7-1.2,1.4-0.6
				c0.2-0.3,0.5-0.7,0.4-1c-0.6-3,1.1-5.7,1.6-8.5c-4,7.8-7,16.1-9.8,24.4c-0.6,1.7-2.5,2.8-2.2,5.1c1.7-2,1.7-2,2.2-5.2
				c1.1,0.5,1.4-0.3,1.7-1.1c0.5-1.1,0.9-2.2,1.4-3.2c-0.3,1.3-0.7,2.6-1,3.9c0.2,0.1,0.4,0.1,0.5,0.2c0.7-1.6,1.5-3.2,2.2-4.9
				c-0.2-0.1-0.3-0.2-0.5-0.3C215.3,19,214.9,19.4,214.5,19.8z M230.8-27.8c-0.4,1.2-0.8,2.5-1.2,3.7c0.1,0,0.3,0.1,0.4,0.1
				C230.3-25.3,230.6-26.6,230.8-27.8c1.7,1.7,0.3,3.9,0.7,6.1c0.7-2.1,1.3-4.2,2-6.3c-2-2.2,0.2-4,0.5-6.2c1.6,1,2.2,0.1,2.6-1.2
				c0.8-2.3,1.6-4.6,2.5-6.9s2-4.4,2.9-6.7c0.8-2,1.5-4.1,2.1-6.2c0.5-1.9-0.2-4.2,1.9-5.5c0.1-0.1,0.1-0.5,0.2-0.7
				c-2.5-0.3,0.2-2.4-1.1-3.5c-1.2,3.1-2.5,6.1-3.6,9.2c-0.3,0.9-1.5,2.2,0.3,2.9c-0.4,0.6-1,1.8-1,1.8c-1.5-0.7-1.5,0.7-1.8,1.5
				c-1.8,4.6-3.4,9.2-5.1,13.7C232.8-33.1,231.8-30.4,230.8-27.8z M254.9-74.7c-0.3-0.1-0.5-0.2-0.8-0.3c-5.1,13-10.2,26-15.2,39.1
				c-2.8,7.4-5.3,15-8.1,22.5c-2.4,6.5-5.1,13-7.5,19.5c-5.3,14.1-10.5,28.3-15.7,42.4c-1.4,3.7-2.8,7.3-4,11.1
				c-1.1,3.3-1.8,6.8-2.9,10.1c-1.8,5.5-3.9,10.8-5.7,16.3c-0.6,1.8-1.2,4.1-0.6,5.7c0.8,2.2,0.6,3.9-0.4,5.8c-1.1,2.2-2,4.4-2.8,6.7
				c-0.3,0.8-0.1,1.7-0.2,2.5c0.3,0.1,0.6,0.2,0.9,0.3c0.9-1.6,2.1-3.1,2.7-4.8c1.7-4.6,3.1-9.3,4.9-13.8c1.3-3.3,2.2-6.7,2.6-10.2
				c0.2-1.7,0.8-3.4,1.5-4.9c1.7-3.8,3.9-7.3,5.4-11.2c4.4-11,8.6-22.1,12.8-33.3c2.7-7.2,5.2-14.4,7.7-21.7c0.4-1.2,0.6-2.6,0.6-3.9
				c-0.1-3,0.3-5.8,1.6-8.5c0.5-1,0.7-2.2,1.1-3.3c-0.7,0.1-0.9,0.3-1.1,0.6c-2.3,6.1-4.7,12.1-6.9,18.2c-2.8,7.7-5.4,15.5-8.2,23.2
				c-2.7,7.4-5.6,14.7-8.4,22c-1.1,3-2.1,6-3.1,9c-2.4,7.1-4.9,14.1-7,21.3c-0.6,2.1-1.5,3.6-3.1,5.6c2.2-6.8,4-13,6.1-19.2
				c3.9-11.5,8-22.9,12.1-34.4c2.5-7,5.1-14,7.6-21c0.8-2.3,1.4-4.6,2.2-6.9c2.5-6.8,5.2-13.5,7.7-20.2c2-5.4,3.9-10.8,5.9-16.2
				c2.1-5.5,4.2-11,6.3-16.5c2.4-6.3,4.8-12.6,7.2-18.9C251.9-66.2,253.4-70.4,254.9-74.7z M188.3,114.9c-0.2,0-0.4-0.1-0.6-0.1
				c-0.3,0.5-0.6,1-0.8,1.5c-2.9,9.1-5.7,18.2-8.5,27.3c-2.3,7.2-4.6,14.5-6.8,21.7c-0.4,1.1-0.9,2.4-0.4,3.6c1.4-2.5,2.8-4.9,4.1-7.3
				c1.3-2.5,2.9-4.8,3.7-7.5c3.9-12.2,7.5-24.6,11.2-36.8c0.3-0.9,0.4-1.7,0.6-2.6c-0.2,0-0.3-0.1-0.5-0.1c-0.6,1.2-1.3,2.3-2.1,3.9
				C188.2,116.8,188.3,115.8,188.3,114.9z M276.3-136.5c-0.2-0.1-0.5-0.2-0.7-0.4c-1,2.1-1.2,4.7-3.6,5.8c-0.2,0.1-0.2,0.4-0.3,0.7
				c-3.3,8.1-6.5,16.1-9.8,24.2c-1.4,3.4-2.7,6.8-4,10.2c-0.1,0.2,0.1,0.4,0.2,0.6c0.3-0.2,0.7-0.5,1-0.7c0.1,0.4,0.2,0.9,0.3,1.9
				c1.9-3.5,3.8-6.2,2.8-9.8c-0.1-0.4,0.6-1.7,0.9-1.7c2.2,0,2.6-1.8,3.2-3.4c3.2-8.6,6.4-17.2,9.5-25.8
				C276-135.3,276.1-135.9,276.3-136.5z M164.9,135.1c-0.4,0.6-0.8,1.2-1,1.9c-0.8,2.6-1.6,5.3-2.4,7.9c-1.7,5.5-3.5,11.1-5.3,16.6
				c-1.3,3.9-2.4,7.9-4,11.7c-1.3,3.1-2.2,6.3-2.4,9.8C151.9,179.5,160.9,151.7,164.9,135.1z M257.1-92.9c-0.1,0-0.3-0.1-0.4-0.1
				c-0.4,0.7-0.8,1.4-1.1,2.2c-0.8,1.9-2.4,4-2.1,5.7c0.4,2.5-2.1,2.7-2.3,4.7c-0.2,2.1-1.5,4.1-2.2,6.2c0,0.1,0.2,0.3,0.2,0.5
				c0.3-0.4,0.6-0.9,0.8-1.3c0.2,0.1,0.3,0.1,0.5,0.2c-0.6,1.9-1.2,3.7-1.8,5.6c1.2-1.3,2-2.8,2.6-4.3c1.5-3.8,2.5-7.8,4.8-11.4
				c1.5-2.2,2-5.1,2.9-7.7c-1,0.5-1.3,1.3-1.8,2.1c-0.1,0.2-0.5,0.3-1,0.5C256.7-91.2,256.9-92.1,257.1-92.9z M132.9,290.7
				c4.4-4.8,5.5-10.5,6.9-16.2c-0.9,0.3-1.9,0.7-2.1,1.3C136,280.6,134.5,285.4,132.9,290.7z M135.4,228.7c-2.6,8.1-5.2,16.2-7.7,24.4
				c0.4,0.1,0.7,0.2,1.1,0.3C131.2,245.2,133.7,237,135.4,228.7z M167,185.7c-0.2-0.1-0.3-0.3-0.5-0.4c-0.7,0.7-1.8,1.3-2.1,2.1
				c-1.4,3.9-2.6,7.9-3.8,11.9c-0.1,0.4,0.5,1,0.8,1.9c1.6-2.2,2.5-4.3,2.5-6.4c-0.1-2.6,0.7-4.7,2.1-6.8
				C166.6,187.3,166.7,186.5,167,185.7z M129.3,304.9c2.4-2.3,4.7-8.6,5.2-13.7c-1,0.9-2.1,1.5-2.4,2.3
				C131,297.1,129.1,300.6,129.3,304.9z M138.6,272.8c2.4-2.1,2.7-5.1,4-7.5c1.7-3.3,0.6-6.5,1.5-9.6
				C142.2,261.4,140.4,267.1,138.6,272.8z M248-50.2c3.3-5.6,5.3-11.9,7.2-18.1C252.2-62.6,249.5-56.7,248-50.2z M72.3,362.2
				c0.3-0.7,0.7-1.3,0.8-2c0.5-4.2,1-8.4,1.5-12.6C72.3,352.3,71.3,357,72.3,362.2z M116.2,191.7c-2.5,4.6-3.2,9.8-4.5,14.7
				C113.6,201.7,116.4,197.2,116.2,191.7z M228.4,21.6c-3.6,5-5.6,10.6-4.8,14C225.7,30.7,227.2,26.2,228.4,21.6z M130.3,228.2
				c3-5.8,4.1-10.4,3.3-12.7C132.2,219.9,130.7,223.9,130.3,228.2z M234.5-57.3c0.4,0.2,0.9,0.4,1.3,0.5c1.2-5.5,4.5-10.2,4.7-16
				C238.5-67.6,236.5-62.5,234.5-57.3z M66.8,401.8c-2.1,3.2-3.4,6.8-3.4,10.7C64.5,409,65.7,405.4,66.8,401.8z M117.4,335.1
				c2.5-1.7,4.1-6,3.3-8.8C119,329,118.5,331.9,117.4,335.1z M251.9-41.8c-3.1,1.5-2.1,4.3-3,6.3C251-36.9,251.5-39.1,251.9-41.8z
				 M260.3,9c-2.2,1.9-4,7.1-3.6,9.7C258,15.2,259.2,12.1,260.3,9z M282.3-47.5c-0.2-0.1-0.4-0.2-0.6-0.3c-1.5,3.6-3,7.1-4.4,10.7
				c0.2,0.1,0.4,0.2,0.6,0.3C279.4-40.4,280.9-44,282.3-47.5z M290.1-66.8c-0.3-0.1-0.6-0.2-0.9-0.3c-1.2,2.8-2.3,5.5-3.5,8.3
				c0.3,0.1,0.5,0.2,0.8,0.3C287.7-61.2,288.9-64,290.1-66.8z M147.4,191.2c-0.3-0.1-0.6-0.3-1-0.4c-0.5,1.9-1,3.7-1.4,5.6
				c0.2,0.1,0.5,0.1,0.7,0.2C146.3,194.8,146.9,193,147.4,191.2z M100.6,242c0.2,0,0.4,0.1,0.6,0.1c0.8-2.6,1.7-5.2,2.5-7.8
				c-0.2-0.1-0.4-0.1-0.6-0.2C102.3,236.7,101.4,239.4,100.6,242z M318.9,82.6c1.6-1.7,1.8-4.4,4.4-4.1C320.6,77,320.6,77,318.9,82.6z
				 M344.9,8.8c2.5-0.6,3.2-2.2,1.9-3.9C346.2,6.1,345.6,7.3,344.9,8.8z M126.1,260.9c1-2,1.8-4,1.7-6.2
				C126.1,256.5,126.1,258.7,126.1,260.9z M81.5,355.9c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4,1.3-0.8,2.7-1.5,4.7
				C81.1,358.8,81.1,357.3,81.5,355.9z M76.7,369.8c-1.9,3.3-1.9,3.3-1.5,5.9C75.8,373.3,76.2,371.5,76.7,369.8z M70.6,386.8
				c0.1,0.1,0.3,0.2,0.4,0.3c1-3.1,2-6.1,2.9-9.2c-0.2-0.1-0.3-0.1-0.5-0.2C72.6,380.7,71.6,383.8,70.6,386.8z M111.4,209.1
				c-0.2-0.1-0.5-0.1-0.8-0.2c-0.4,1.4-0.9,2.8-1.4,4.5C110.9,212.2,111.7,210.9,111.4,209.1z M253.4-45.8c1.3-1.4,1.3-1.4,1.6-3.5
				C252.7-48.4,252.7-48.4,253.4-45.8z M263.9-3.4c2.3-2.9,2.9-4.9,2.1-6.7C265.3-7.7,264.6-5.5,263.9-3.4z M238-3.1
				c-2.9,0.7-3,1-2.2,3.8C236.5-0.6,237.3-1.9,238-3.1z M404.5-154c-0.2,0-0.3-0.1-0.5-0.1c-0.4,1.2-0.8,2.4-1.1,3.6
				c0.2,0,0.3,0.1,0.5,0.1C403.7-151.6,404.1-152.8,404.5-154z M333.3,50.4c-0.5-1.3,0-2.7-1.5-3.5c-0.2,1.5-0.3,2.7-0.5,4.1
				C332,50.8,332.4,50.6,333.3,50.4z M378.2-21c-0.9,0.2-1.6,0.3-2.4,0.5C377.2-19.1,377.2-19.1,378.2-21z M306.5,124.5
				c-0.3-0.2-0.6-0.5-0.9-0.7c-0.1,1.2-1.9,1.2-1.2,3.6C305.2,126.2,305.9,125.4,306.5,124.5z M267.3-78.8c-0.2-0.1-0.5-0.2-0.7-0.4
				c-0.6,1.8-1.2,3.7-1.7,5.5c0.1,0,0.2,0.1,0.3,0.1C265.9-75.3,266.6-77,267.3-78.8z M260.5-63.4c0.2,0.1,0.3,0.2,0.5,0.2
				c0.3-0.8,0.7-1.7,1-2.5c0.3-0.7,0.4-1.5,0.6-2.3c-0.1,0-0.3-0.1-0.4-0.1C261.6-66.5,261-64.9,260.5-63.4z M272.1-76.9
				c-0.1,0-0.3-0.1-0.4-0.1c-0.5,1.5-1,3.1-1.5,4.6c0.1,0,0.3,0.1,0.4,0.1C271.1-73.9,271.6-75.4,272.1-76.9z M231.6,13.9
				c-0.2-0.2-0.4-0.4-0.6-0.6c-0.8,0.8-1.5,1.7-2.6,2.9C231.1,16.7,231.1,14.9,231.6,13.9z M260.9-89.9c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.5,1.6-1,3.1-1.5,4.7c0.1,0,0.2,0.1,0.2,0.1C259.9-86.9,260.4-88.4,260.9-89.9z M265.6-84.3c-0.2-0.2-0.5-0.3-0.7-0.5
				c-0.4,1.1-0.9,2.2-1.3,3.3c0.2,0.1,0.4,0.2,0.6,0.3C264.6-82.3,265.1-83.3,265.6-84.3z M255.8-61.6c0.1,0,0.2,0.1,0.3,0.1
				c0.4-1.3,0.8-2.7,1.3-4c-0.1,0-0.2-0.1-0.3-0.1C256.7-64.3,256.3-62.9,255.8-61.6z M222.4-3.7c0.1,0,0.2,0,0.2,0.1
				c0.5-1.3,1-2.6,1.5-4c-0.2-0.1-0.5-0.1-0.7-0.2C223-6.5,222.7-5.1,222.4-3.7z M277.5-141.2c1.5-2.3,1.5-2.3,1.5-4.7
				C278.4-144,277.9-142.6,277.5-141.2z M292.2-72.2c-1.3,1-1.3,1-1.3,2.8C291.5-70.6,291.9-71.4,292.2-72.2z M144.6,200.6
				c-0.1-0.1-0.3-0.2-0.4-0.2c-0.3,1-0.6,2-0.9,2.9c0.1,0.1,0.3,0.1,0.4,0.2C144,202.5,144.3,201.6,144.6,200.6z M328.8,57.1
				c0.7-1.2,1.2-1.8,1.6-2.5C329.4,54.9,328.2,54.9,328.8,57.1z M324,66.1c0,0.2,0,0.4,0,0.5c0.8,0,1.5-0.1,2.3-0.1
				c0-0.3,0-0.6-0.1-0.9C325.5,65.8,324.8,65.9,324,66.1z M382.4-29.4c0-0.2,0-0.5-0.1-0.7c-0.7,0.1-1.4,0.2-2,0.4c0,0.2,0,0.4,0.1,0.6
				C381.1-29.2,381.7-29.3,382.4-29.4z M374.9-14.9c-0.6,0.4-1,0.6-1.3,0.9c-0.1,0.1,0.2,0.7,0.3,0.7c0.3,0,0.7-0.1,0.9-0.4
				C374.9-13.8,374.8-14.2,374.9-14.9z M189.7,111c0.1,0.1,0.3,0.2,0.4,0.3c0.3-0.3,0.6-0.5,0.7-0.8c0.2-0.4,0.1-0.9,0.2-1.3
				c-0.2,0-0.4-0.1-0.6-0.1C190.2,109.7,190,110.4,189.7,111z M355.4-19.6c0-0.3-0.1-0.6-0.1-0.9c-0.6,0.1-1.1,0.1-1.7,0.2
				c0.1,0.3,0.1,0.9,0.2,0.9C354.3-19.3,354.8-19.5,355.4-19.6z M208.8,37.7c0.2,0.1,0.5,0.2,0.7,0.3c0.2-0.7,0.3-1.4,0.5-2.1
				c-0.2,0-0.4-0.1-0.6-0.1C209.2,36.4,209,37,208.8,37.7z M267.1-65c0.1,0,0.2,0.1,0.3,0.1c0.3-0.9,0.5-1.7,0.8-2.6
				c-0.1,0-0.2-0.1-0.3-0.1C267.6-66.7,267.3-65.8,267.1-65z M263.8-57.3c0.2,0.1,0.3,0.1,0.5,0.2c0.3-0.7,0.6-1.4,0.9-2
				c-0.2-0.1-0.3-0.2-0.5-0.3C264.4-58.6,264.1-58,263.8-57.3z M255.7-52.2c0.2,0.1,0.3,0.2,0.5,0.2c0.2-0.7,0.5-1.5,0.7-2.2
				c-0.1,0-0.3-0.1-0.4-0.1C256.2-53.6,255.9-52.9,255.7-52.2z M189.3,29.4c0.1,0,0.2,0.1,0.4,0.1c0.2-0.6,0.4-1.2,0.6-1.8
				c-0.1,0-0.2-0.1-0.3-0.1C189.8,28.2,189.6,28.8,189.3,29.4z M198,7c0.1,0,0.2,0.1,0.4,0.1c0.1-0.5,0.2-0.9,0.3-1.4
				c-0.1,0-0.2,0-0.2-0.1C198.2,6.1,198.1,6.5,198,7z M259.3,278.4c-0.2-0.4-0.3-0.8-0.6-0.9c-0.1-0.1-0.7,0.3-0.7,0.4
				c0,0.3,0.1,0.7,0.4,0.8C258.6,278.8,259,278.5,259.3,278.4z M367.3-21.2c0.2,0.2,0.3,0.5,0.3,0.5c0.3-0.1,0.6-0.3,0.8-0.4
				c-0.1-0.2-0.3-0.6-0.4-0.6C367.9-21.6,367.6-21.3,367.3-21.2z M383-36.9c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0.1-0.5,0.1-0.6,0.3
				c-0.2,0.3-0.2,0.7-0.4,1.1C382.2-36.2,382.6-36.6,383-36.9z"/>
            </g>
        </g>

        <g>
			<path class="st0" d="M113.1,277l-3.4-1.9l2.2-9.4l-6.4,2.2l-1.1,6h-4.9c0-3.3-2.1-6.1-5.2-7.1l-3.7,1.1c2,1,3.5,2.7,4.1,4.9h-6.4
			c0,0-1.5-4.5-9-3.4c0,0,2.6,2.2,2.2,4.1c-11.8-0.2-23.3,3.6-32.6,10.9c0,0-17.2,16.5-32.6,13.5l0.4-3.7c8.6-0.1,16.9-3.1,23.6-8.6
			c12-9.4,15-12.7,22.1-13.5c0,0-13.5-6.7-40.4,12.7l-3.4,1.5l0.4-4.1c0,0,24.3-21.3,47.1-13.8l5.6-1.1c0,0-11.6-12.3-40-4.5l0.7-3.7
			c14.7-5.3,31-3.4,44.2,5l5.2-0.7c-12.5-11.2-29.4-16.3-46-13.8l3.7-5.2c17.1-0.3,33.8,5.7,46.8,16.8l4.1,1.1c0,0-5.6-18.3-31.8-25.1
			l2.2-3c0,0,24.3,4.9,34.1,26.6l4.1-0.4c0,0-2.6-21.7-20.6-32.9l1.1-2.6c12.5,7.7,21.1,20.3,23.6,34.8l4.1,0.7
			c0,0-1.9-25.4-13.1-39.3l2.2-2.6c0,0,12.3,19.8,15,39.3c0,0,7.9-17.2-1.5-41.2l3.4-1.1c0,0,9.4,21.7,3.7,38.5l7.9-5.2l0.4,3
			l-5.6,5.2l-1.1,1.5l6,0.4c0,0,4.5-26.2,22.8-36.7l3,1.5c-7.9,5.2-13.9,12.8-17.2,21.7c0,0,16.5-24.7,34.8-27.3l2.2,1.5
			c-11,3.2-20.3,10.4-26.2,20.2l5.2-0.4c0,0,16.8-21.7,38.5-15.3v3.4c0,0-19.1-1.1-29.2,9.4l2.6,1.1c5.7-4.9,13.3-6.7,20.6-4.9
			l-4.1,5.2c-4.9-0.6-9.8,0.2-14.2,2.2l8.6,2.6l-3,2.6c0,0-17.2-5.2-19.5-0.4c0,0-7.9-3.7-16.1,5.2l-4.5,1.9l-0.7,2.2
			c0,0,13.5-9,19.5-6c-14.1,5.4-24.5,17.5-27.7,32.2l-3.4-1.9l4.1-15.3l-3-0.4L113.1,277z"/>
			<path class="st0" d="M222,286.8c0,0-15,6.4-18.3,20.6h6.4c2.9-9.5,11.4-16.4,21.3-17.2l4.1,1.5c0,0-16.8,4.5-20.2,15l5.6,0.4
				c0,0,9.4-13.1,22.5-11.2l1.5,2.2c0,0-15.3,3.7-19.5,9l6.4,1.1c5-4.3,11.4-6.5,18-6.4l2.6,1.9c-3.7,0.6-7.4,1.8-10.9,3.4l3.7,0.7
				c0,0,10.9-0.7,13.5,4.1c0,0-12-0.7-16.8-1.5v2.4h-3.7l-2.2-3h-4.5v5.6l-4.1-0.7l-1.5-5.6l-9-0.7l-0.4,3.7l-4.5,0.7V309l-9.7,0.4
				l-0.4,6l-3.7,1.1l0.7-7.5l-5.2-0.4v8.6l-2.6,1.1l-1.9-9.4l-4.5-0.7l2.2,10.5l-3,2.2l-3.4-13.1l-4.1-1.1l3.4,15.7l-3.7,2.6l-3-18.7
				l-9.4,6c5.1,4.1,8,10.3,7.9,16.8l-3,2.2c-0.9-8.6-6.1-16.1-13.8-19.8l3.4-1.9l14.6-7.5c0,0-8.6-24.3,9-31.8l2.6,1.9
				c-7.7,7.8-10.2,19.3-6.4,29.6l3.7,0.7c0,0-4.1-19.8,10.5-28.8l4.1,1.5c0,0-13.5,13.1-10.1,28.8l4.5,0.7c0,0,0-19.5,14.6-26.2l3,2.2
				c-7.2,6-12,14.3-13.5,23.6l4.9,0.7c0,0,2.6-17.2,16.8-21.3L222,286.8z"/>
			<path class="st0" d="M294,136.2c0,0-20.3-1.1-34.9,29.3c0,0-16-3.2-18.7,17.8c0,0-3.7,14.7-29.9,17.5c-4.4,0.2-8.5,2.1-11.6,5.2
				l2.5,3c0,0,1.9-5.1,16.9-5.8s20-10.6,25.7-17.9c0,0-0.9-17.4,18.3-16.6C268,154,279.4,142.3,294,136.2z"/>
        </g>
    </svg>
</div>
</template>

<script>
const st0 = document.getElementsByClassName('st0');
const app = document.getElementById("app");

setTimeout(function(){
    for (let i = 0; i < st0.length; i++) {
        st0[i].classList.add('filled');
    }
  }, 3000);
  
  export default {
    name: "Flower",
	props: {
		height: String,
		width: String,
		color: String
	}
  };
</script>

<style scoped lang="scss">
	/*
	* KEYFRAMES 
	**/
	@keyframes fillAction {
    0% {
        transform: translate(-450px, 1600px);
    }
    100% {
      transform: translate(0, 0px);
    }
  }
  @keyframes fillInverseAction {
    0% {
        transform: translate(350px, 1000px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes appear{
    0% {
        fill: transparent;
    }
    100% {
        fill: white;
    }
  }
  svg{
    height: 100%;
	 width: 100%;
  }

  .a-fill-inverse{
    animation-name: fillInverseAction;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-fill-mode: forwards; 
  }
  .a-fill{
    animation-name: fillAction;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 2.5s;
    animation-fill-mode: forwards; 
  }

  .a-fill path,  .a-fill-inverse path{
    fill: #7C7E80;
}

.a-fill.filling, .a-fill-inverse.filling{
    transform: translate(0, 8px);
}

.a-fill.done, .a-fill-inverse.done {
 transform:translate(0, 0px);
 transition-duration: 400ms;

}
.st0{
    fill: transparent;
    position: relative;
    z-index: 999;
    animation: appear;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-delay: 2s;
    animation-duration: 1s;
}
.st0.filled{
    fill: white;
}
.light-gray .a-fill path, .light-gray .a-fill-inverse path{
	fill: #DDDDDD;
}
</style>