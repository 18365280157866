import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ScrollAnimation from "./directives/scrollAnimation";
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;

Vue.directive('scrollanimation', ScrollAnimation);

Vue.use(VueCookies);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
