<template>
  <!-- Institute -->
  <section id="institute">
    <div class="content-text">
      <h2 v-scrollanimation title="Notre institut" class="title-classic"><span class="bordered-title">Notre</span>
        Institut</h2>
      <div class="mobile content-img" v-scrollanimation>
        <img src="@/assets/images/institut.jpg" alt="institut"/>
      </div>
      <p v-scrollanimation>Accordez vous un instant de détente, de relaxation, votre bien-être est notre priorité.</p>
      <div class="align-content-right">
        <a href="#care" class="btn-classic" v-scrollanimation title="Découvrir nos soins">Découvrir nos soins</a>
      </div>
    </div>
    <div class="content-img" v-scrollanimation>
      <img src="@/assets/images/institut.jpg" alt="institut"/>
    </div>
    <StepIndicatorUi link="#care" textFirst="Découvrir" textSecond="nos soins" v-scrollanimation />
  </section>
</template>

<script>
  import StepIndicatorUi from "@/components/ui/StepIndicatorUi.vue";

  export default {
    name: "Institute",
    components: {
      StepIndicatorUi
    }
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/variables';

  /** INSTITUTE */
  #institute {
    .mobile{
      display:none !important;
    }
    text-align: center;

    .content-text {
      width: 50%;
      text-align: left;
      display: inline-block;
      vertical-align: top;
      padding-right: 30px;

      p {
        font-size: $home-paragraph;
        font-weight: 300;

        &.before-enter {
          opacity: 0;
          transform: translateY(100px);
          transition: all 1s ease-out;
          transition-delay: 0.5s;
        }

        &.enter {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }

    .content-img {
      width: 40%;
      height: 70vh;
      text-align: left;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;
      margin-top:50px;
      border-radius: 50px;

      &.before-enter {
        opacity: 0;
        transform: translateX(100px);
        transition: all 1s ease-out;
        transition-delay: 0.5s;
      }

      &.enter {
        transform: translateX(0px);
        opacity: 1;
      }
    }

    img {
      width: 145%;
      margin-left: -100px;
    }

    .next-step-indicator,
    .btn-classic {
      &.before-enter {
        opacity: 0;
        transition: opacity 2s ease-out;
        transition-delay: 1s;
      }

      &.enter {
        opacity: 1;
        width: auto;
      }
    }

  }
  @media screen and (max-width:1217px) {
      #institute {
      .align-content-right{
        padding-right:0; 
      }
      .content-text {
        p {
          font-size: $home-paragraph - 5px;
        }
      }
    }
  }

  @media screen and (max-width:791px) {

    #institute {
      height:auto;
      .next-step-indicator{
          position:static;
      }
      .content-text {
        width: 100%;
        display: block;
        padding-right: 0px;
      }
      .align-content-right{
        text-align:center;
          .btn-classic{
            display:block;
            margin-bottom: 30px;
          }
          a{
            margin-left:0;
          }
      }


    .content-img {
        width: 100%;
        height: 30vh;
        text-align: left;
        overflow: hidden;
        border-radius: 50px;
        display:none;
        &.mobile{
          display:block !important;
          img{
            margin-left:0;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }
</style>