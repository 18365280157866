<template>
  <!-- Contacts -->
  <section id="contact">
    <h2 v-scrollanimation title="Notre institut" class="title-classic">Venez-nous voir</h2>
    <div class="aligned-block">
      <p v-scrollanimation class="opens"> Du<br />

        <span class="indented-text">Mardi au vendredi 9h-19h et le samedi 9h-16h</span>
      </p>

      <p v-scrollanimation class="opens">
        Métro<br />

        <span class="indented-text">Arrêt la poterie</span>
      </p>
    </div>
    <div class="aligned-block">
      <p>
        <a v-scrollanimation href="tel:+33299514099" class="phone" title="Lien Téléphone Institut Delphine">02 99 51 40
          99</a><br />
        <a v-scrollanimation href="https://www.google.fr/maps/place/Institut+Delphine/@48.0914774,-1.6428278,17z/data=!3m1!4b1!4m5!3m4!1s0x480edf1213969c0d:0xb7e66b29c963813b!8m2!3d48.0914774!4d-1.6406391"
          title="Lien Adresse Institut Delphine" class="adress">
          Centre commercial La Poterie <br />
          5 Bis Place Du Ronceray <br />
          35200 Rennes <br />
        </a>
      </p>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Contact",
    components: {}
  };
</script>

<style scoped lang="scss">
  @import '@/assets/scss/variables';

  #contact {
    height: 70vh;
    background-color: $dark-black;
    color: $white;
    padding-top: 50px;

    .opens {
      line-height: 65px;

      .indented-text {
        padding-left: 30px;
      }

      &.before-enter {
        opacity: 0;
        transform: translateY(100px);
        transition: all 1s ease-out;
        transition-delay: 0.5s;
      }

      &.enter {
        transform: translateY(0px);
        opacity: 1;
      }
    }

    a {
      color: $white;
    }

    text-align: center;

    h2 {
      color: $white;
      text-align: left;
    }

    .aligned-block {
      display: inline-block;
      font-size: 30px;
      width: 60%;

      &+.aligned-block {
        padding-left: 50px;
        width: 30%;
      }

      p {
        text-align: left;
      }

      .phone {
        font-size: 50px;
        word-spacing: 15px;
        line-height: 130px;

        &.before-enter {
          opacity: 0;
          transition: opacity 1s ease-out;
          transition-delay: 0.75s;
        }

        &.enter {
          opacity: 1;
        }
      }

      .adress {
        text-decoration: none;
        &.before-enter {
          opacity: 0;
          transition: opacity 1s ease-out;
          transition-delay: 1s;
        }

        &.enter {
          opacity: 1;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media screen and (max-width:1412px){
      #contact {
      .aligned-block{
          .phone {
            font-size: 40px;
          }
        }
    }
  }
  @media screen and (max-width:1190px){
      #contact {
      height: auto;
      .aligned-block{
          .phone {
            font-size: 30px;
          }
        }
    }
  }
  @media screen and (max-width:930px){
      #contact {
      height: auto;

      .aligned-block {
        display: inline-block;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;

        &+.aligned-block {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
        }
        .phone {
          font-size: 30px;
        }
      }
    }
  }
  @media screen and (max-width:791px) {
    #contact {
      height: auto;
      .opens {
        line-height: 35px;

        .indented-text {
          padding-left: 0px;
        }
      }

      .aligned-block {
        display: inline-block;
        padding-left: 0px;
        padding-right: 0px;
        font-size: 20px;
        width: 100%;

        &+.aligned-block {
          padding-left: 0px;
          padding-right: 0px;
          width: 100%;
        }

        p {
          text-align: left;
        }

        .phone {
          font-size: 30px;
          word-spacing: 15px;
          line-height: 50px;
        }
      }
    }
  }

</style>