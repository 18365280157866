<template>
    <div id="accessibility-bar">
        <p>
            Ici nous nous adaptons à vos paramètres et c'est encore mieux de pouvoir les changer  
            <button class="btn-classic" @click='showAccessibilityWindow'>
                Accessibilité
            </button>
        </p>

        <div :class="{hidden: windowVisibility}">
            <div>
            Contraste :
                <label class="switch">
                    <input type="checkbox" @click="setParams('contrast')" :checked="contrast">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
            Police Dyslexiques :
                <label class="switch">
                    <input type="checkbox" @click="setParams('font-dysl')" :checked="fontDysl">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
            Sans animations :
                <label class="switch">
                    <input type="checkbox" @click="setParams('no-animation')" :checked="noAnimation">
                    <span class="slider round"></span>
                </label>
            </div>
            <!--<button @click="setParams('contrast')">Contraste</button>
            <button @click="setParams('font-dysl')">Police Dyslexiques</button>
            <button @click="setParams('animation')">Animations</button>-->
        </div>
    </div>
</template>

<script>
  export default {
    name: "AccessibilityBar",
    data() {
        return{
            windowVisibility: true,
            contrast: false,
            noAnimation: false,
            fontDysl: false
        }
    },
    mounted(){
      const param = this.$cookies.get("Accessibility");
        if(param != null){
            this.noAnimation = (param.split('&')[0].split(':')[1] === 'true');
            this.contrast = (param.split('&')[1].split(':')[1] === 'true');
            this.fontDysl = (param.split('&')[2].split(':')[1] === 'true');
        }

    },
    methods : {
        showAccessibilityWindow(){
            if( this.windowVisibility == false ){
                this.windowVisibility = true;
            }else{
                this.windowVisibility = false;
            }
        },
        setParams(param){
            switch (param) {
                case 'contrast':
                    this.contrast==false ? this.contrast=true : this.contrast=false;
                break;

                case 'font-dysl':
                    this.fontDysl==false ? this.fontDysl=true : this.fontDysl=false;
                break;

                case 'no-animation':
                    this.noAnimation==false ? this.noAnimation=true : this.noAnimation=false;
                break;

                default:
                break;
            }
            this.$cookies.set("Accessibility", 
            "no-animation:"+this.noAnimation+"&"+
            "contrast:"+this.contrast+"&"+
            "font-dysl:"+this.fontDysl);

            this.$emit('accessibility-param', this.$cookies.get("Accessibility"));
        }
    }
  };
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
    #accessibility-bar{
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;   
        background-color:$dark-black;
        color: white;

        .btn-classic{
            color: white;
            border:white;
            font-size:14px;
            border:1px solid white;
            padding:2px 8px;
            cursor:pointer;
                &:hover{
                    background-color:white;
                    color:black;
                }
            }
    }
    .hidden{
        display:none;
    }

p{
    font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width:769px){
    #accessibility-bar{
        p{
            font-size: 14px;
            .btn-classic{
                font-size:12px; 
                display: block;
                margin: 10px auto;
            }
        }
    }
}
</style>