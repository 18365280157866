<template>
  <!-- Products -->
  <section id="products">
    <h2 v-scrollanimation title="Nos produits" class="title-classic"><span class="bordered-title">Nos</span> Produits</h2>
    <div class="centered-content">
      <h3 v-scrollanimation>Nos produits du moment</h3>
      <div v-for="(product, index) in products" :key="index" class="product-item" 
      @click="goToProductPage(product.id, product.name)">
          <img :src="product.imageUrl">
          <p>
          {{
            product.name
          }}</p>
          <p>
          {{
            product.price
          }} €</p>
          <button class="btn-classic" @click="goToProductPage(product.id, product.name)" :title="'Découvrir le produit ' + product.name">Découvrir</button>
        </div>
        <div class="link-block">
          <a v-scrollanimation class="btn-classic" href="https://app.kiute.com/l_institut-delphine/productCategory/6524067046621184">Tous nos produits</a>
          <!-- <router-link to="/store">Tous nos produits</router-link> -->
        </div>
    </div>
    <StepIndicatorUi link="#contact" textFirst="Prenons" textSecond="contact" />
  </section>
</template>

<script>
  import StepIndicatorUi from "@/components/ui/StepIndicatorUi.vue";
  // import ProductService from "@/core/store.service.ts";
  //const productService = new ProductService();

  export default {
    name: "Products",
    data() {
      return {
        products: [],
      }
    },
    mounted() {
      // productService.getProductsByCategory(86636504).then(
      //     response => {
      //       this.products = response.data.items;
      //     }
      //   )
      //   .catch(
      //     function (error) {
      //       console.log(error);
      //     }
      //   )
      },
    methods: {
      goToProductPage(id, name)
      {
        window.location.href = "#!/"+name+"/p/"+id;
      },
      addToCart(productId)
      {
        // Ecwid.Cart.addProduct(productId);
      },
    },
    components: {
          StepIndicatorUi
      },

  };
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';
  #products {
    height: auto;
    margin-bottom: 50px;
    .next-step-indicator{
      bottom:0;
    }
    h2 {
      text-align: left;
    }
    h3{
      text-transform:uppercase;
    }
    .centered-content{
      text-align:center;
    }
    .product-item{
      display:inline-block;
      text-align:center;
      width:20%;
      cursor:pointer;
      opacity: 0.8;

      &:hover{
        opacity: 1;
      }
      img {
          width: auto;
          max-height: 200px;
      }
      .btn-classic{
          background-color: transparent;
          color: $dark-black;
          border: 4px solid $dark-black;
          border-radius: 15px;
          padding: 5px 15px;
          text-transform: uppercase;
          text-decoration: none;
          font-weight: bold;
          font-size: 14px;

          &:hover{
            cursor:pointer;
            background-color:$dark-black;
            color:white;
          }
      }
    }
    .link-block{
      margin-top: 50px;
    }
  }

  @media screen and (max-width:1217px) {
     #products {
        .product-item{
          width:50%;
        }
      }
  }

  @media screen and (max-width:791px) {
      #products {
        padding-bottom: 100px;
       }
  }
  @media screen and (max-width: 480px) {
       #products {
       padding-bottom: 100px;
        .product-item{
          width:100%;
          padding-top:15px;
          padding-bottom:15px;
        }
      }
  }
</style>