<template>
    <div :class="{'previous-step-indicator' : previous, 'next-step-indicator' : !previous}">
        <a class="icon-arrow" v-bind:href="link" alt="Passer à la section suivante">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 490.656 490.656" style="enable-background:new 0 0 490.656 490.656;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M487.536,120.445c-4.16-4.16-10.923-4.16-15.083,0L245.339,347.581L18.203,120.467c-4.16-4.16-10.923-4.16-15.083,0
                            c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.136l234.667-234.667
                            C491.696,131.368,491.696,124.605,487.536,120.445z"/>
                    </g>
                </g>
            </svg>
          <span>{{ textFirst }} <br /> {{ textSecond }}</span>
        </a>
    </div>
</template>

<script>
  export default {
    name: "StepIndicatorUi",
    props: {
        textFirst: String,
        textSecond: String,
        link: String,
        previous: Boolean
    },
  };
</script>

<style lang="scss">
   @import '@/assets/scss/variables';
   
    /** STEP INDICATOR STYLE **/
    .next-step-indicator, .previous-step-indicator{
        position: absolute;
        bottom: 50px;
        left: 50px;
        text-align: left;
        font-weight: 300;

        a {
            color: $dark-black;
            text-decoration: none;
            span{
             display: inline-block;
             vertical-align: middle;
            }
        }
    }
    .next-step-indicator{
            svg{
                fill:$dark-black;
                display: inline-block;
                width: 20px;
                vertical-align: middle;
                padding-right: 15px;
            }
    }
    .previous-step-indicator{
        a{
            color: $white;
                svg{
                    fill:$white;
                    display: inline-block;
                    width: 20px;
                    vertical-align: middle;
                    padding-left: 15px;
                    transform:rotate(180deg);
                }
        }

    }
    /** END STEP INDICATOR STYLE **/
    @media screen and (max-width:768px){
        .next-step-indicator{
            left:15px;
        }
        .previous-step-indicator{
            position:static; 
            padding-left:15px;
            padding-bottom:15px;
        }
    }
</style>